import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'

import s from './Signin.module.css'
import { LocalStorageUtils } from '../../common/local-storage-utils'
import { ROUTES } from '../../common/router/routes'
import { FormError } from '../../common/shared-components/form-error/FormError'
import { FormButton } from '../../common/shared-components/buttons/form-button/FormButton'
import { authApi } from '../../auth/auth.api'
import { LoginDto } from '../../auth/dto/login.dto'
import { setToken } from '../../auth/auth.slice'
import { useAppDispatch } from '../../common/store'
import { useEffect } from 'react'
import { captureError } from '../../common/monitoring/captureError'

export function SignIn() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [logout] = authApi.useLazyLogoutQuery()
  const [login, { error, isLoading }] = authApi.useLoginMutation()

  const SigninSchema = Yup.object().shape({
    email: Yup.string().required('This field should not be empty'),
    password: Yup.string().required('This field should not be empty'),
  })

  const signIn = async (loginDto: LoginDto) => {
    try {
      const { accessToken } = await login(loginDto).unwrap()
      dispatch(setToken(accessToken))
      LocalStorageUtils.saveAccessToken(accessToken)
      LocalStorageUtils.setIsOldUser()
      navigate('/')
    } catch (e) {
      console.error(e)
      captureError(e)
    }
  }

  useEffect(() => {
    if (LocalStorageUtils.getAccessToken()) {
      logout()
      LocalStorageUtils.clearAccessToken()
    }
  }, [])

  return (
    <div>
      <div className={`${s.title} ${error ? s.title_error : ''}`}>Log in</div>
      <FormError error={error} />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={SigninSchema}
        onSubmit={signIn}
        validateOnMount={true}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <div className={s.input__wrap}>
              <Field
                className={s.input}
                id="email"
                type="email"
                name="email"
                placeholder={'E-mail'}
                autoComplete="username"
              />
              {errors.email && touched.email ? <div className={s.error}>{errors.email}</div> : null}
            </div>
            <div className={s.input__wrap}>
              <Field
                id="current-password"
                className={s.input}
                type="password"
                name="password"
                placeholder={'Password'}
                autoComplete="current-password"
              />
              {errors.password && touched.password ? (
                <div className={s.error}>{errors.password}</div>
              ) : null}
            </div>
            <div className={s.restore}>
              <Link to={ROUTES.restore.path} className={s.link}>
                Forgot password
              </Link>
            </div>
            <div className={s.form__btn}>
              <FormButton isLoading={isLoading} disabled={!isValid} />
            </div>
          </Form>
        )}
      </Formik>
      <div className={s.accounted}>
        Don’t have an account?{' '}
        <Link to={ROUTES.signup.path}>
          <span className={s.link}>Sign Up</span>
        </Link>
      </div>
    </div>
  )
}
