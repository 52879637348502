import { Navigate } from 'react-router-dom'
import { authApi } from '../../auth/auth.api'
import { LocalStorageUtils } from '../../common/local-storage-utils'
import { ROUTES } from '../../common/router/routes'
import { useEffect } from 'react'
import { useAppDispatch } from '../../common/store'
import { baseApi } from '../../common/rtk-query/base.api'

export const LogoutPage = () => {
  const [logout, { isLoading }] = authApi.useLazyLogoutQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (LocalStorageUtils.getAccessToken()) {
      logout()
      LocalStorageUtils.clearAccessToken()
      dispatch(baseApi.util.resetApiState())
    }
  }, [logout, dispatch])

  return isLoading ? null : <Navigate to={ROUTES.login.path} />
}
