import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocalStorageUtils } from '../common/local-storage-utils'
import { RootState } from '../common/store'

export interface IAuthState {
  accessToken: string | null
}

const authState: IAuthState = {
  accessToken: LocalStorageUtils.getAccessToken(),
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload
    },
    logOut: (state) => {
      state.accessToken = null
    },
  },
})

export const { setToken, logOut } = authSlice.actions

export const authReducer = authSlice.reducer

export const selectToken = (root: RootState) => root.auth.accessToken
