import React from 'react'
import { ErrorInfo, Suspense, ReactNode } from 'react'
import { ErrorPage } from '../pages/error-page/ErrorPage'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    if (prevProps.children !== this.props.children && this.state.hasError) {
      this.setState({ hasError: false })
    }
  }

  reloadApp = () => {
    this.setState({ hasError: false })
  }

  render() {
    const { hasError } = this.state

    const { children } = this.props

    if (hasError) {
      return (
        <>
          <Suspense fallback={null}>
            <ErrorPage reloadApp={this.reloadApp} />
          </Suspense>
        </>
      )
    }

    return children
  }
}
