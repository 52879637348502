import s from './ErrorPage.module.scss'

interface ErrorPageProps {
  reloadApp: () => void
}

export const ErrorPage = ({ reloadApp }: ErrorPageProps) => {
  return (
    <div className={s.ErrorPage}>
      <div className={s.error_window}>
        <p className={s.error_text}>Whoops, something went wrong.</p>

        <button className={s.reload_button} onClick={reloadApp}>
          Try again
        </button>
      </div>
    </div>
  )
}
