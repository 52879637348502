export class LocalStorageUtils {
  // jwt access token
  private static readonly ACCESS_TOKEN_LS_KEY = 'access_token'

  // if the user has already been logged in
  private static readonly OLD_USER_LS_KEY = 'old_user'

  static saveAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN_LS_KEY, token)
  }

  static getAccessToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN_LS_KEY)
  }

  static clearAccessToken() {
    localStorage.removeItem(this.ACCESS_TOKEN_LS_KEY)
  }

  static setIsOldUser() {
    localStorage.setItem(this.OLD_USER_LS_KEY, 'true')
  }

  static getIsOldUser(): boolean {
    return localStorage.getItem(this.OLD_USER_LS_KEY) !== null
  }
}
