import s from './CreateModelingInvoiceForm.module.scss'
import { Shape } from '../../../../common/yup'
import * as Yup from 'yup'
import { UserDto } from '../../../../auth/dto/user.dto'
import { CreateModelingInvoiceDto } from '../../data/dto/create-modeling-invoice.dto'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { DEFAULT_MODEL_PRICE } from '../../../billing/data/constants/buy-3d-models'
import { isFetchError } from '../../../../common/rtk-query/fetch-error'
import { createModelingInvoiceApi } from '../../data/create-modeling-invoice.api'
import { useState } from 'react'
import { TextFieldElement } from 'react-hook-form-mui'
import { SaveButton } from '../../../../common/shared-components/buttons/save-button/SaveButton'
import { NotificationMessage } from '../../../../common/shared-components/notification-message/NotificationMessage'

interface CreateModelingInvoiceFormProps {
  user: UserDto
  amount: number
}

type ICreateModelingInvoice = Omit<CreateModelingInvoiceDto, 'userId'>

const createModelingInvoiceSchema = Yup.object().shape<Shape<ICreateModelingInvoice>>({
  amount: Yup.number().integer().min(1),
  pricePerOneModel: Yup.number().integer().min(0),
})

export const CreateModelingInvoiceForm = ({ user, amount }: CreateModelingInvoiceFormProps) => {
  const [createInvoice, { isLoading }] = createModelingInvoiceApi.useCreateModelingInvoiceMutation()
  const [error, setError] = useState<string>('')

  const hookFormMethods = useForm<ICreateModelingInvoice>({
    resolver: yupResolver(createModelingInvoiceSchema),
    defaultValues: {
      amount,
      pricePerOneModel: DEFAULT_MODEL_PRICE,
    },
  })

  const {
    formState: { isValid },
    watch,
    handleSubmit,
  } = hookFormMethods

  const onApply = async (data: ICreateModelingInvoice) => {
    try {
      const { invoiceUrl } = await createInvoice({
        userId: user.id,
        ...data,
      }).unwrap()
      window.location.replace(invoiceUrl)
    } catch (e) {
      if (isFetchError(e)) {
        setError(e.data.message)
      } else {
        setError('Something terrible has happened, please contact the developers')
      }
    }
  }

  return (
    <FormProvider {...hookFormMethods}>
      <form onSubmit={handleSubmit(onApply)}>
        <div className={s.user_data}>
          <h3>User:</h3>
          <p>
            <b>Name:</b> <span>{user.name}</span>
          </p>
          <p>
            <b>Email:</b> <span>{user.email}</span>
          </p>
          <p>
            <b>Organization:</b> <span>{user.organization}</span>
          </p>
        </div>

        <h3>Configuration:</h3>

        <div className={s.configuration_form}>
          <span>
            <label htmlFor="pricePerOneModel">
              3d models number (initially, the user requested <strong>{amount}</strong> models, but
              you can change it):
            </label>
            <TextFieldElement className="standard-input" name="amount" />
          </span>

          <span>
            <label htmlFor="pricePerOneModel">Price per one model:</label>
            <TextFieldElement className="standard-input" name="pricePerOneModel" />
          </span>

          <span>
            Total price: <strong>${watch('amount') * watch('pricePerOneModel')}</strong>
          </span>
        </div>

        {error && (
          <div>
            <NotificationMessage type="error" text={error} />
          </div>
        )}

        <SaveButton loading={isLoading} disabled={!isValid} type="submit" title="Create invoice" />
      </form>
    </FormProvider>
  )
}
