import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiAccordionsDetails: {
  defaultProps?: ComponentsProps['MuiAccordionDetails']
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordionDetails']
  variants?: ComponentsVariants['MuiAccordionDetails']
} = {
  styleOverrides: {
    root: {
      '&.details': {
        font: 'var(--font-body)',
        padding: '0 52px 16px 36px',
      },
    },
  },
}

export default muiAccordionsDetails
