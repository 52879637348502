import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './common/store'
import { App } from './App'

import './index.scss'
import { ThemeProvider } from '@mui/material'
import theme from './common/ui/muiConfig'
import { initMonitoring } from './common/monitoring/monitoring'

initMonitoring()

const container = document.getElementById('root')!
const root = createRoot(container)

// </React.StrictMode> was disabled due bug with mediapipe https://github.com/google-ai-edge/mediapipe/issues/2963#issuecomment-1312482962
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
)
