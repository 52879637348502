import { useGetUserQuery } from '../../../../auth/user.api'
import { Beginning } from '../beginning/Beginning'
import { PageLoader } from '../../../../common/shared-components/page-loader/PageLoader'

export const BeginningController = () => {
  const {
    data: user,
    isError,
    isLoading,
  } = useGetUserQuery(undefined, { refetchOnMountOrArgChange: true })

  if (isError) return <p>Not found</p>

  if (isLoading) return <PageLoader />

  return <>{user && <Beginning user={user} />}</>
}
