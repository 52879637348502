import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiMenuItems: {
  defaultProps?: ComponentsProps['MuiMenuItem']
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem']
  variants?: ComponentsVariants['MuiMenuItem']
} = {
  styleOverrides: {
    root: {
      '&.row-menu-item': {
        font: 'var(--font-body)',
        lineHeight: 'var(--line-height-sm)',
        color: 'var(--color-dark)',
        padding: '8px 10px',
        borderBottom: '1px solid #E9EEF1',
        minHeight: 'unset',
        backgroundColor: 'transparent',
        '& a': {
          color: 'var(--color-dark)',
        },
        '&:hover': {
          background: 'var(--color-gray-light)',
        },
        '&:active': {
          color: 'var(--color-white)',
          background: 'var(--color-blue)',
        },
        '&:active a': {
          color: 'var(--color-white)',
        },
        '&.duplicate': {
          color: 'var(--color-orange)',
        },
        '&.delete': {
          color: 'var(--color-red)',
        },
      },
      '&.row-menu-item:last-of-type': {
        borderBottom: 'none',
      },
      '&.logout-item': {
        fontFamily: 'var(--font-main)',
        fontSize: 'var(--body-size)',
        lineHeight: 'var(--line-height-sm)',
        marginTop: '5px',
        padding: '8px 10px',
        minHeight: 'unset',

        '&:active': {
          background: 'var(--color-blue)',
        },
        '&:active a': {
          color: 'var(--color-white)',
        },
      },
      '&.logout-item a': {
        color: 'var(--color-dark)',
      },
    },
  },
}

export default muiMenuItems
