import MenuItem from './MenuItem'
import { HeaderUserInfo } from '../HeaderUserInfo/HeaderUserInfo'
import { useMediaQuery } from 'react-responsive'
import logoSVG from '../../../../assets/logo.svg'
import s from './Sidebar.module.css'
import { useCallback, useState } from 'react'
import { getDefaultProtectedRoute, getSidebarItems } from '../../../router/routes'
import classNames from 'classnames'
import { useGetUserQuery } from '../../../../auth/user.api'
import { Link } from 'react-router-dom'

export function Sidebar() {
  const { data: user } = useGetUserQuery()
  const isDesktop = useMediaQuery({ query: '(min-width:768px)' })
  const isCurledUp = useMediaQuery({ query: '(max-width:1200px)' })
  const [isOpen, setIsOpen] = useState(false)
  const currentYear = new Date().getFullYear()

  const handleMenuIconClick = () => {
    setIsOpen(!isOpen)
  }

  const getAvailableMenuItems = useCallback(() => {
    if (!user) return []

    const sidebarItems = getSidebarItems()

    return sidebarItems
      .filter((route) => {
        return (
          !(isDesktop && route.mobileOnly) &&
          !route.exclude &&
          (!route.roles.length || route.roles.includes(user.role))
        )
      })
      .map((route) => <MenuItem key={route.sidebarLabel} route={route} setIsOpen={setIsOpen} />)
  }, [isDesktop, user])

  return (
    <>
      <aside className={classNames(s.sidebar, { [s.sidebar__nav_active]: isOpen })}>
        {!isDesktop && (
          <button
            className={classNames(s.hamburger, s.hamburger__slider, { [s.is_active]: isOpen })}
            type="button"
            onClick={handleMenuIconClick}
          >
            <span className={s.hamburger_box}>
              <span className={s.hamburger_inner} />
            </span>
          </button>
        )}
        <>
          {isCurledUp ? (
            <>
              <button
                className={classNames(s.hamburger, s.hamburger__slider, { [s.is_active]: isOpen })}
                type="button"
                onClick={handleMenuIconClick}
              >
                <span className={s.hamburger_box}>
                  <span className={s.hamburger_inner} />
                </span>
              </button>
              <Link to={getDefaultProtectedRoute(user)} className={s.logo}>
                <img src={logoSVG} alt="logo" />
              </Link>
              <nav className={classNames(s.nav, { [s.nav__active]: isOpen })}>
                <div className={s.link_wrap}>
                  {!isDesktop && <HeaderUserInfo />}
                  {getAvailableMenuItems()}
                </div>
                <div className={s.info}>
                  {/*{isSidebarRouteGuard(ROUTES.help) && <MenuItem route={ROUTES.help} />}*/}
                  <div className={s.copyright}>© {currentYear} Trillion Technology</div>
                </div>
              </nav>
            </>
          ) : (
            <>
              <Link to={getDefaultProtectedRoute(user)} className={s.logo}>
                <img src={logoSVG} alt="logo" />
              </Link>
              <nav className={classNames(s.nav, { [s.nav__active]: isOpen })}>
                <div className={s.link_wrap}>
                  {(!isDesktop || isCurledUp) && <HeaderUserInfo />}
                  {getAvailableMenuItems()}
                </div>
                <div className={s.info}>
                  {/*{isSidebarRouteGuard(ROUTES.help) && <MenuItem route={ROUTES.help} />}*/}
                  <div className={s.copyright}>© {currentYear} Trillion Technology</div>
                </div>
              </nav>
            </>
          )}
        </>
      </aside>
    </>
  )
}
