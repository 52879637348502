import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../common/store'

interface IUserProfileState {
  profileImage: string | undefined
  companyLogo: string | undefined
}

const userProfileState: IUserProfileState = {
  profileImage: undefined,
  companyLogo: undefined,
}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: userProfileState,
  reducers: {
    setProfileImage: (state, { payload }: PayloadAction<string | undefined>) => {
      state.profileImage = payload
    },
    setCompanyLogo: (state, { payload }: PayloadAction<string | undefined>) => {
      state.companyLogo = payload
    },
  },
})

export const userProfileReducer = userProfileSlice.reducer

export const userProfileActions = userProfileSlice.actions

export const selectProfileImage = (root: RootState) => root.user.profileImage
export const selectCompanyLogo = (root: RootState) => root.user.companyLogo
