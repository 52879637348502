import { baseApi } from '../common/rtk-query/base.api'
import { UserDto } from './dto/user.dto'
import { UpdateUserProfileDto } from './dto/update-user-profile.dto'
import { UpdateUserTutorialDto } from './dto/update-user-tutorial.dto'

const USERS_URL = 'users'

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<UserDto, void>({
      query: () => {
        return { url: `${USERS_URL}/me`, method: 'GET' }
      },
      providesTags: () => ['User'],
    }),
    updateUserProfile: build.mutation<UserDto, UpdateUserProfileDto>({
      query: (UpdateDto) => ({
        url: `${USERS_URL}/profile`,
        method: 'PUT',
        data: { ...UpdateDto },
      }),
      invalidatesTags: () => ['User'],
    }),
    updateUserTutorial: build.mutation<UserDto, UpdateUserTutorialDto>({
      query: (UpdateDto) => ({
        url: `${USERS_URL}/tutorial`,
        method: 'PUT',
        data: { ...UpdateDto },
      }),
      invalidatesTags: () => ['User'],
    }),
    getUserById: build.query<UserDto, string>({
      query: (id) => ({ url: `${USERS_URL}/${id}`, method: 'GET' }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useUpdateUserProfileMutation,
  useGetUserByIdQuery,
  useUpdateUserTutorialMutation,
} = usersApi
