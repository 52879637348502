import { Button, ButtonPropsVariantOverrides } from '@mui/material'
import { LoaderSpinner } from '../../loader-spinner/LoaderSpinner'
import classNames from 'classnames'

interface SaveButtonProps {
  className?: string
  loading: boolean
  disabled?: boolean
  handleSubmit?: () => void
  title?: string
  type?: 'button' | 'submit'
  variant?: keyof ButtonPropsVariantOverrides
}

export const SaveButton = ({
  className,
  loading,
  disabled,
  handleSubmit,
  title = 'Save changes',
  type = 'button',
  variant = 'transparentArrow',
}: SaveButtonProps) => {
  if (loading) {
    return (
      <div className={classNames(className)}>
        <Button variant="loading" startIcon={<LoaderSpinner size={3} />} disabled>
          Processing...
        </Button>
      </div>
    )
  }
  return (
    <div className={classNames(className)}>
      <Button variant={variant} type={type} onClick={handleSubmit} disabled={disabled}>
        {title}
      </Button>
    </div>
  )
}
