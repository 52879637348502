import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'
import checkmark from '../../../assets/materialUI/checkmark.svg'
import checkmarkDisabled from '../../../assets/materialUI/checkmarkDisabled.svg'

const muiCheckboxes: {
  defaultProps?: ComponentsProps['MuiCheckbox']
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox']
  variants?: ComponentsVariants['MuiCheckbox']
} = {
  styleOverrides: {
    root: {
      '&.checkbox': {
        padding: '0',

        '&.Mui-checked': {
          '::after': {
            content: '""',
            backgroundImage: `url(${checkmark})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'absolute',
            width: '10px',
            height: '7px',
          },
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          borderRadius: '4px',
          border: '1px solid #49ACCF',
          background: 'var(--color-blue-light)',
        },
      },
      '&.checkbox:not(.Mui-checked):hover': {
        backgroundColor: 'transparent',
        border: '1px solid #909699',
        borderRadius: '4px',
        color: 'transparent',
        width: '18px',
        height: '18px',
        overflow: 'hidden',
      },
      '&.checkbox .MuiSvgIcon-root': {
        borderRadius: '4px',
        color: 'transparent',
        width: '18px',
        height: '18px',
        border: '1px solid #E9EEF1',
      },
      '&.checked-disabled': {
        padding: '0',
        '&.Mui-checked': {
          '::after': {
            content: '""',
            backgroundImage: `url(${checkmarkDisabled})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'absolute',
            width: '10px',
            height: '7px',
          },
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          borderRadius: '4px',
          border: '1px solid #909699',
          background: 'var(--color-gray-light)',
        },
      },
      '&.checked-disabled:not(.Mui-checked):hover': {
        backgroundColor: 'transparent',
        border: '1px solid #909699',
        borderRadius: '4px',
        color: 'transparent',
        width: '18px',
        height: '18px',
        overflow: 'hidden',
      },
      '&.checked-disabled .MuiSvgIcon-root': {
        borderRadius: '4px',
        color: 'transparent',
        width: '18px',
        height: '18px',
        border: '1px solid border #909699',
      },
      '&.element': {
        marginLeft: '11px',
        marginRight: '-16px',
      },
    },
  },
}

export default muiCheckboxes
