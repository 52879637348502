import classNames from 'classnames'
import s from './CardWrapper.module.scss'
import React from 'react'

interface CardWrapperProps {
  className?: string
  children: React.ReactNode
  withPadding?: boolean
  isRounded?: boolean
  padding?: string | number
  margin?: string | number
}

export const CardWrapper = ({
  className,
  children,
  withPadding,
  isRounded,
  padding,
  margin,
}: CardWrapperProps) => {
  return (
    <div
      className={classNames(
        s.card_wrapper,
        {
          [s.card_padding]: withPadding,
          [s.card_rounded]: isRounded,
        },
        className,
      )}
      style={{ padding, margin }}
    >
      {children}
    </div>
  )
}
