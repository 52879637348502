import { useEffect, useState } from 'react'
import s from './StepItem.module.scss'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import checkmarkGreen from '../../../../assets/checkmark-green.svg'
import checkmarkStep from '../../../../assets/checkmark-step.svg'
import { useNavigate } from 'react-router-dom'

interface StepItemProps {
  checkmark: boolean | undefined
  title: string
  text: string
  btn?: string
  link?: string
  expandedIndexes: number[]
  setExpandedIndexes: (value: (prevIndexes: number[]) => number[]) => void
  index: number
}

export const StepItem = ({
  checkmark,
  title,
  text,
  btn,
  link,
  expandedIndexes,
  setExpandedIndexes,
  index,
}: StepItemProps) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpandedIndexes((prevIndexes: number[]) => {
      const newIndexes = new Set<number>(prevIndexes)
      if (!checkmark && !newIndexes.has(index)) {
        newIndexes.add(index)
      } else if (checkmark && newIndexes.has(index)) {
        newIndexes.delete(index)
      }
      return Array.from(newIndexes)
    })
  }, [checkmark, index, setExpandedIndexes])

  useEffect(() => {
    const firstIndex = expandedIndexes[0]
    if (expandedIndexes.length > 0 && firstIndex === index) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [expandedIndexes, index])

  return (
    <Accordion className="launchSteps" expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <div className={s.title__wrapper}>
          <div>
            <img src={checkmark ? checkmarkGreen : checkmarkStep} alt={'Checkmark Green'} />
          </div>
          <div className={s.title}>{title}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="details">
        <div className={s.content}>
          <div className={s.text}>{text}</div>
        </div>
        {btn && link && (
          <Button variant="transparentArrow" onClick={() => navigate(link)}>
            {btn}
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
