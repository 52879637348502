import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiDialog: {
  defaultProps?: ComponentsProps['MuiDialog']
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog']
  variants?: ComponentsVariants['MuiDialog']
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.upload .MuiDialog-container': {
        '@media (min-width: 768px)': {
          alignItems: 'flex-start',
          transform: 'translateY(145px)',
        },
      },
      '&.upload .MuiDialog-container .MuiPaper-root': {
        height: '100%',
        maxHeight: 'unset',
        borderRadius: 'unset',
        margin: '0',
        '@media (min-width: 768px)': {
          maxHeight: 'calc(100% - 64px)',
          height: 'auto',
          maxWidth: '360px',
          width: '100%',
          margin: '32px',
          borderRadius: '8px',
          boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        },
      },
      '&.upload .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.cancel .MuiDialog-container .MuiPaper-root': {
        height: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        margin: 0,
        width: '100%',
        borderRadius: '0',
        '@media (min-width: 768px)': {
          height: 'auto',
          margin: '32px',
          maxWidth: '360px',
          maxHeight: 'calc(100% - 64px)',
          width: '100%',
          borderRadius: '8px',
          boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        },
      },
      '&.cancel .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.getintouch .MuiDialog-container .MuiPaper-root': {
        height: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        margin: 0,
        width: '100%',
        borderRadius: '0',
        '@media (min-width: 768px)': {
          height: 'auto',
          margin: '32px',
          maxWidth: '360px',
          maxHeight: 'calc(100% - 64px)',
          width: '100%',
          borderRadius: '8px',
          boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        },
      },
      '&.getintouch .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.success .MuiDialog-container .MuiPaper-root': {
        height: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        margin: 0,
        width: '100%',
        borderRadius: '0',
        '@media (min-width: 768px)': {
          height: 'auto',
          margin: '32px',
          maxWidth: '360px',
          maxHeight: 'calc(100% - 64px)',
          width: '100%',
          borderRadius: '8px',
          boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        },
      },
      '&.success .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.change .MuiDialog-container .MuiPaper-root': {
        maxWidth: '360px',
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        overflow: 'unset',
      },
      '&.change .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.changeLog .MuiDialog-container .MuiPaper-root': {
        maxWidth: '540px',
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
        overflow: 'unset',
      },
      '&.changeLog .MuiBackdrop-root': {
        backgroundColor: 'rgba(34, 45, 51, 0.3)',
      },
      '&.trillion-web-cdk-popup .MuiDialog-container .MuiPaper-root': {
        maxHeight: 'unset',
        [theme.breakpoints.up('desktop')]: {
          maxWidth: '1700px',
          width: '80vw',
          height: '90vh',
        },
        [theme.breakpoints.down('desktop')]: {
          maxWidth: 'unset',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          borderRadius: 0,
        },
      },
    }),
  },
}

export default muiDialog
