import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import s from './Restore.module.css'
import { ROUTES } from '../../../../common/router/routes'
import { FormButton } from '../../../../common/shared-components/buttons/form-button/FormButton'
import { useForgotPasswordMutation } from '../../../../auth/auth.api'
import { ForgotPasswordDto } from '../../../../auth/dto/forgot-password.dto'
import { notificationCenterActions } from '../../../../common/shared-components/notification-center/data/notification-center.slice'
import { parseNotificationError } from '../../../../common/shared-components/notification-center/data/helpers/parse-notification-error'
import { useAppDispatch } from '../../../../common/store'
import { captureError } from '../../../../common/monitoring/captureError'

interface RestoreProps {
  setIsFormSubmitted: (value: boolean) => void
}

export function Restore({ setIsFormSubmitted }: RestoreProps) {
  const dispatch = useAppDispatch()
  const [forgotPassword] = useForgotPasswordMutation()
  const RestoreSchema = Yup.object().shape({
    email: Yup.string()
      .email('Field should contain a valid e-mail')
      .required("The 'Email' field is required"),
  })

  const onSubmit = async (values: ForgotPasswordDto) => {
    try {
      await forgotPassword({
        email: values.email,
      }).unwrap()
      setIsFormSubmitted(true)
    } catch (e) {
      console.error(e)
      dispatch(
        notificationCenterActions.show({
          type: 'error',
          title: `Error when restoring the password`,
          description: parseNotificationError(e),
        }),
      )
      captureError(e)
    }
  }

  return (
    <div className={s.restore}>
      <div className={s.title}>Forgot your password?</div>
      <div className={s.subtitle}>
        We’ll send you instructions to reset your password and get you back on track.
      </div>
      <div className={s.login__form_wrap}>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={RestoreSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className={s.input__wrap}>
                <Field className={s.input} type="text" name="email" placeholder={'E-mail'} />
                {errors.email && touched.email ? (
                  <div className={s.error}>{errors.email}</div>
                ) : null}
              </div>
              <div className={s.form__btn}>
                <FormButton disabled={!isValid} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={s.accounted}>
        Don’t have an account?{' '}
        <Link to={ROUTES.signup.path}>
          <span className={s.link}>Sign Up</span>
        </Link>
      </div>
    </div>
  )
}
