import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiPopover: {
  defaultProps?: ComponentsProps['MuiPopover']
  styleOverrides?: ComponentsOverrides<Theme>['MuiPopover']
  variants?: ComponentsVariants['MuiPopover']
} = {
  styleOverrides: {
    paper: {
      overflow: 'visible',
      boxShadow: '0px 2px 20px 0px rgba(34, 45, 51, 0.08)',
    },
  },
}

export default muiPopover
