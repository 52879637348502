import { baseApi } from '../../../common/rtk-query/base.api'
import { InvoiceCreatedDto } from './dto/invoice-created.dto'
import { SelectEnterpriseBillingPackageDto } from './dto/select-enterprise-billing-package.dto'

const BILLING_URL = 'billing'

export const createEnterpriseInvoiceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createEnterpriseInvoice: build.mutation<InvoiceCreatedDto, SelectEnterpriseBillingPackageDto>({
      query: (data) => ({
        url: `${BILLING_URL}/create-enterprise-invoice`,
        method: 'POST',
        data,
      }),
    }),
  }),
})
