import { usersApi } from '../../../../auth/user.api'
import { CreateModelingInvoiceForm } from '../create-modeling-invoice-form/CreateModelingInvoiceForm'
import { isFetchError } from '../../../../common/rtk-query/fetch-error'
import { NotificationMessage } from '../../../../common/shared-components/notification-message/NotificationMessage'
import { PageLoader } from '../../../../common/shared-components/page-loader/PageLoader'

interface CreateModelingInvoiceFormControllerProps {
  userId: string
  amount: number
}

export const CreateModelingInvoiceFormController = ({
  userId,
  amount,
}: CreateModelingInvoiceFormControllerProps) => {
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = usersApi.useGetUserByIdQuery(userId)

  if (isUserLoading) {
    return <PageLoader />
  }

  if (userError || !user) {
    return (
      <>
        {isFetchError(userError) && (
          <NotificationMessage type={'error'} text={userError.data.message} />
        )}
      </>
    )
  }

  return <CreateModelingInvoiceForm user={user} amount={amount} />
}
