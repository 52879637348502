import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  TCreateNotificationCenterMessage,
  TNotificationCenterMessage,
} from './types/notification-center'
import { createUUID } from '../../../uuid'

interface NotificationCenterState {
  notifications: TNotificationCenterMessage[]
}

const notificationCenterState: NotificationCenterState = {
  notifications: [],
}

export const notificationCenterSlice = createSlice({
  name: 'notificationCenter',
  initialState: notificationCenterState,
  reducers: {
    show: (state, { payload }: PayloadAction<TCreateNotificationCenterMessage>) => {
      state.notifications.push({ id: createUUID(), ...payload })
    },
    remove: (state, { payload }: PayloadAction<string>) => {
      const toRemove = state.notifications.findIndex((n) => n.id === payload)
      state.notifications.splice(toRemove, 1)
    },
  },
  selectors: {
    selectNotifications: (state) => state.notifications,
  },
})

export const notificationCenterReducer = notificationCenterSlice.reducer

export const notificationCenterActions = notificationCenterSlice.actions

export const notificationCenterSelectors = notificationCenterSlice.selectors
