import * as Yup from 'yup'
import s from './Signup.module.css'
import { Formik, Field, Form } from 'formik'
import { selectCredentials, setCurrentStage, setNavigateForward } from '../data/signup.slice'
import { useState } from 'react'
import { ICompanyInfoData } from '../data/sign-up-stage'
import { FormButton } from '../../../common/shared-components/buttons/form-button/FormButton'
import { useSignupMutation } from '../../../auth/auth.api'
import { useAppDispatch, useAppSelector } from '../../../common/store'

export const CompanyInfo = () => {
  const [signUp, { isLoading }] = useSignupMutation({ fixedCacheKey: 'shared-signup' })
  const credentials = useAppSelector(selectCredentials)
  const [privacyToggle, setPrivacyToggle] = useState(false)
  const dispatch = useAppDispatch()

  const companySchema = Yup.object().shape({
    company: Yup.string().required("The 'Organization' field is required"),
    privacyToggle: Yup.boolean().isTrue('This field must be checked'),
  })

  const onSubmit = async (companyInfoData: ICompanyInfoData) => {
    try {
      await signUp({
        name: credentials.name,
        email: credentials.email,
        password: credentials.password,
        jobTitle: companyInfoData.job,
        organization: companyInfoData.company,
        site: companyInfoData.website,
      }).unwrap()
      dispatch(setCurrentStage('verify'))
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = () => {
    dispatch(setNavigateForward(false))
  }

  function handleCheckbox() {
    setPrivacyToggle(() => !privacyToggle)
  }

  return (
    <>
      <Formik
        initialValues={{
          job: '',
          website: '',
          company: '',
          privacyToggle: false,
        }}
        validationSchema={companySchema}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {({ errors, touched, isValid }) => (
          <Form className={`${s.form}`} noValidate>
            <Field
              className={s.input}
              name="job"
              type="text"
              autoComplete="on"
              placeholder={'Job title'}
            />
            <div className={s.input__wrap}>
              <Field
                className={s.input}
                name="company"
                type="text"
                autoComplete="on"
                placeholder={'Organization*'}
              />
              {errors.company && touched.company ? (
                <div className={s.error}>{errors.company}</div>
              ) : null}
            </div>
            <Field
              className={s.input}
              type="text"
              autoComplete="on"
              name="website"
              placeholder={'Web-site'}
            />
            <div className={s.checkbox__wrap}>
              <Field
                type="checkbox"
                className={s.input__checkbox}
                name="privacyToggle"
                onClick={() => handleCheckbox()}
                id="privacyToggle"
              />
              <div className={privacyToggle ? `${s.checked} ${s.checkbox}` : s.checkbox} />
              <label htmlFor="privacyToggle" className={s.checkbox__text}>
                I agree with the <span className={s.link}>Terms of Service</span> and{' '}
                <span className={s.link}>Privacy Police</span>
              </label>
              {errors.privacyToggle && touched.privacyToggle ? (
                <div className={s.error}>{errors.privacyToggle}</div>
              ) : null}
            </div>
            <div className={s.form__btn} onClick={handleSubmit}>
              <FormButton isLoading={isLoading} disabled={!isValid} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
