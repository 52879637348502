import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiTabItem: {
  defaultProps?: ComponentsProps['MuiTab']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab']
  variants?: ComponentsVariants['MuiTab']
} = {
  styleOverrides: {
    root: {
      '&.packages-tab': {
        position: 'relative',
        zIndex: 1,
      },
    },
  },
}

export default muiTabItem
