import { Outlet } from 'react-router-dom'
import { selectToken } from '../../auth/auth.slice'
import { useAppSelector } from '../store'
import { NavigateToDefaultUnprotectedRoute } from './routes'

interface RequireLoginProps {
  require: boolean
}

export const RequireLogin = ({ require }: RequireLoginProps) => {
  const token = useAppSelector(selectToken)

  return token || !require ? <Outlet /> : <NavigateToDefaultUnprotectedRoute />
}
