import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../common/store'
import { UploadEntityWithErrorDto } from './dto/upload-entity-with-error.dto'

interface IEditProductState {
  models: Array<UploadEntityWithErrorDto>
  images: Array<UploadEntityWithErrorDto>
  loaderImage: Array<UploadEntityWithErrorDto>
}

const editProductState: IEditProductState = {
  models: [],
  images: [],
  loaderImage: [],
}

export const editProductSlice = createSlice({
  name: 'editProduct',
  initialState: editProductState,
  reducers: {
    addModelsUploads: (state, { payload }: PayloadAction<Array<UploadEntityWithErrorDto>>) => {
      state.models.push(...payload)
    },
    addImagesUploads: (state, { payload }: PayloadAction<Array<UploadEntityWithErrorDto>>) => {
      state.images.push(...payload)
    },
    addLoaderImageUpload: (state, { payload }: PayloadAction<Array<UploadEntityWithErrorDto>>) => {
      state.loaderImage = [...payload]
    },
    updateModelUpload: (state, { payload }: PayloadAction<Partial<UploadEntityWithErrorDto>>) => {
      const index = state.models.findIndex((u) => u.id === payload.id)
      if (index !== -1) {
        state.models[index] = { ...state.models[index], ...payload }
      }
    },
    updateImageUpload: (state, { payload }: PayloadAction<Partial<UploadEntityWithErrorDto>>) => {
      const index = state.images.findIndex((u) => u.id === payload.id)
      if (index !== -1) {
        state.images[index] = { ...state.images[index], ...payload }
      }
    },
    updateLoaderImageUpload: (
      state,
      { payload }: PayloadAction<Partial<UploadEntityWithErrorDto>>,
    ) => {
      const index = state.loaderImage.findIndex((u) => u.id === payload.id)
      if (index !== -1) {
        state.loaderImage[index] = { ...state.loaderImage[index], ...payload }
      }
    },
    setImagesArray: (state, { payload }: PayloadAction<Array<UploadEntityWithErrorDto>>) => {
      state.images = [...payload]
    },
    removeModelUpload: (state, { payload }: PayloadAction<string>) => {
      state.models = state.models.filter((u) => u.id !== payload)
    },
    removeImageUpload: (state, { payload }: PayloadAction<string>) => {
      state.images = state.images.filter((u) => u.id !== payload)
    },
    removeLoaderImageUpload: (state, { payload }: PayloadAction<string>) => {
      state.loaderImage = state.loaderImage.filter((u) => u.id !== payload)
    },
    clearUploads: (state) => {
      state.models = []
      state.images = []
      state.loaderImage = []
    },
  },
})

export const editProductReducer = editProductSlice.reducer

export const editProductActions = editProductSlice.actions

export const selectModelsUploads = (root: RootState) => root.editProduct.models

export const selectImagesUploads = (root: RootState) => root.editProduct.images

export const selectLoaderImageUpload = (root: RootState) => root.editProduct.loaderImage
