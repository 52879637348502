import { FetchErrorType, isApplicationError, isFetchError } from '../../rtk-query/fetch-error'
import { NotificationMessage } from '../notification-message/NotificationMessage'
import s from './FormError.module.scss'

interface IFormError {
  error: FetchErrorType
  useMinWidth?: boolean
}

export const FormError = ({ error, useMinWidth = true }: IFormError) => {
  return (
    <>
      {error && (
        <div className={s.error_notifications}>
          {isFetchError(error) && (
            <NotificationMessage
              type={'error'}
              text={error.data.message ?? 'Service unavailable, please try again later'}
              useMinWidth={useMinWidth}
            />
          )}
          {isApplicationError(error) && (
            <NotificationMessage
              type={'error'}
              text={'An error has occurred, please contact the developers'}
              useMinWidth={useMinWidth}
            />
          )}
        </div>
      )}
    </>
  )
}
