import { ErrorBoundary } from './common/error-boundary'
import { AppRouter } from './common/router/AppRouter'
import { NotificationCenter } from './common/shared-components/notification-center/components/notification-center/NotificationCenter'

export function App() {
  return (
    <ErrorBoundary>
      <AppRouter />
      <NotificationCenter />
    </ErrorBoundary>
  )
}
