import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import { RootState } from '../store'
import { Mutex } from 'async-mutex'
import { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios'
import { logOut, setToken } from '../../auth/auth.slice'
import { AccessTokenDto } from '../../auth/dto/access-token.dto'
import { LocalStorageUtils } from '../local-storage-utils'
import { axiosInstance } from '../http/HTTP'
import { IFetchError } from './fetch-error'

const mutex = new Mutex()

const axiosBaseQuery: BaseQueryFn<AxiosRequestConfig, unknown, unknown> = async (request, api) => {
  // set http headers
  if (!request.headers) {
    request.headers = new AxiosHeaders()
    request.headers.set('Content-Type', 'application/json')
  }

  if (request.headers instanceof AxiosHeaders) {
    const token = (api.getState() as RootState).auth.accessToken
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`)
    }
  }

  try {
    const res = await axiosInstance<unknown>(request)
    return { data: res.data }
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: {
          status: error.response?.status,
          data: (error.response as IFetchError)?.data || error.message,
        },
      }
    } else {
      throw error
    }
  }
}

export const baseQueryWithInterceptor: BaseQueryFn<AxiosRequestConfig, unknown, unknown> = async (
  request,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  // make request
  let result = await axiosBaseQuery(request, api, extraOptions)

  // Access token has expired (Unauthorized)
  if ((result.error as AxiosError)?.status === 401) {
    // checking whether the mutex is locked
    // Preventing multiple unauthorized errors
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await axiosBaseQuery(
          {
            url: 'auth/refresh',
            method: 'GET',
            withCredentials: true,
          },
          api,
          extraOptions,
        )
        if (refreshResult?.data) {
          const accessToken = (refreshResult.data as AccessTokenDto).accessToken
          api.dispatch(setToken(accessToken))
          LocalStorageUtils.saveAccessToken(accessToken)
          // retry
          result = await axiosBaseQuery(request, api, extraOptions)
        } else {
          LocalStorageUtils.clearAccessToken()
          api.dispatch(logOut())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      // retry
      result = await axiosBaseQuery(request, api, extraOptions)
    }
  }

  return result
}
