import { Notifications } from '../notifications/Notifications'
import { HeaderUserInfo } from '../HeaderUserInfo/HeaderUserInfo'
import { useMediaQuery } from 'react-responsive'

import s from './Header.module.css'

export function Header() {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    <div className={s.header__wrap}>
      <div className={s.header}>
        <Notifications />
        {isDesktop && <HeaderUserInfo />}
      </div>
    </div>
  )
}
