import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    percentText: true
  }
}

const muiTypographies: {
  defaultProps?: ComponentsProps['MuiTypography']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography']
  variants?: ComponentsVariants['MuiTypography']
} = {
  styleOverrides: {
    root: {
      font: 'var(--font-body)',
    },
  },
  variants: [
    {
      props: { variant: 'percentText' },
      style: {
        font: 'var(--font-body)',
        fontStyle: 'normal',
        color: 'var(--color-gray)',
      },
    },
  ],
}

export default muiTypographies
