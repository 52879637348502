import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiAccordions: {
  defaultProps?: ComponentsProps['MuiAccordion']
  styleOverrides?: ComponentsOverrides<Theme>['MuiAccordion']
  variants?: ComponentsVariants['MuiAccordion']
} = {
  styleOverrides: {
    root: {
      '&.launchSteps': {
        position: 'relative',
        border: 0,
        borderRadius: 'none',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        boxShadow: 'unset',
        borderBottom: '1px solid var(--color-gray-light)',

        '&:before': {
          display: 'none',
        },

        '& .MuiAccordionSummary-root': {
          padding: '20px 0',

          '&:after': {
            position: 'absolute',
            right: 0,
            paddingTop: '3px',
            transform: 'rotate(0)',
            content: 'url("../src/assets/plus-gray.svg")',
            transition: 'transform .2s',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        },

        '& .Mui-expanded': {
          minHeight: 'unset',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
        '&.Mui-expanded .MuiAccordionSummary-root:after': {
          position: 'absolute',
          right: 0,
          paddingTop: '-1px !important',
          transform: 'rotate(180deg)',
          content: 'url("../src/assets/minus-gray.svg")',
          transition: 'transform .2s',
        },
      },
      '&.launchSteps:last-of-type': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
}

export default muiAccordions
