import { CardWrapper } from '../../../../common/shared-components/card-wrapper/CardWrapper'
import { useSearchParams } from 'react-router-dom'
import { NavigateToDefaultProtectedRoute } from '../../../../common/router/routes'
import { FeatureTitle } from '../../../../common/shared-components/feature-title/FeatureTitle'
import { CreateEnterpriseInvoiceFormController } from '../create-enterprise-invoice-form-controller/CreateEnterpriseInvoiceFormController'

export const CreateEnterpriseInvoicePage = () => {
  const [searchParams] = useSearchParams()

  const userId = searchParams.get('user')
  const packageId = searchParams.get('package')

  if (!(userId && packageId)) {
    return <NavigateToDefaultProtectedRoute />
  }

  return (
    <div>
      <FeatureTitle>Create enterprise subscription</FeatureTitle>
      <CardWrapper withPadding isRounded>
        <CreateEnterpriseInvoiceFormController userId={userId} packageId={packageId} />
      </CardWrapper>
    </div>
  )
}
