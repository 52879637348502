import { CardWrapper } from '../../../../common/shared-components/card-wrapper/CardWrapper'
import { useSearchParams } from 'react-router-dom'
import { NavigateToDefaultProtectedRoute } from '../../../../common/router/routes'
import { FeatureTitle } from '../../../../common/shared-components/feature-title/FeatureTitle'
import { CreatePromoPackageInvoiceFormController } from '../create-promo-package-invoice-form-controller/CreatePromoPackageInvoiceFormController'

export const CreatePromoPackageInvoicePage = () => {
  const [searchParams] = useSearchParams()

  const userId = searchParams.get('user')

  if (!userId) {
    return <NavigateToDefaultProtectedRoute />
  }

  return (
    <div>
      <FeatureTitle>Create Promo package invoice</FeatureTitle>
      <CardWrapper withPadding isRounded>
        <CreatePromoPackageInvoiceFormController userId={userId} />
      </CardWrapper>
    </div>
  )
}
