import s from './Signup.module.css'
import Dot from './Dot'
import { Link } from 'react-router-dom'
import { bullets, signUpStages } from '../data/sign-up-stage'
import { selectCurrentStage, selectFirstLoad, selectNavigateForward } from '../data/signup.slice'
import { ROUTES } from '../../../common/router/routes'
import { FormError } from '../../../common/shared-components/form-error/FormError'
import { AnimatePresence, motion } from 'framer-motion'
import { useSignupMutation } from '../../../auth/auth.api'
import { useAppSelector } from '../../../common/store'

export const SignUp = () => {
  const [, { error }] = useSignupMutation({ fixedCacheKey: 'shared-signup' })
  const currentStageName = useAppSelector(selectCurrentStage)
  const isFirstLoad = useAppSelector(selectFirstLoad)
  const isNavigateForward = useAppSelector(selectNavigateForward)
  const CurrentStageComponent = signUpStages.get(currentStageName)!.element

  return (
    <>
      <div className={`${s.title} ${error ? s.title_error : ''}`}>Create an account</div>
      <FormError error={error} />
      <div className="stage">
        <AnimatePresence>
          <motion.div
            initial={
              currentStageName === 'credentials' && isFirstLoad
                ? { x: '0', position: 'absolute' }
                : currentStageName === 'companyInfo'
                ? { x: '150%', position: 'absolute' }
                : currentStageName === 'verify'
                ? { x: '150%', position: 'absolute' }
                : { x: '-150%', position: 'absolute' }
            }
            animate={{ x: '0', position: 'unset' }}
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.9 },
            }}
            exit={
              (currentStageName === 'companyInfo' && !isNavigateForward) ||
              (currentStageName === 'credentials' && !isNavigateForward)
                ? { x: '-150%', position: 'absolute' }
                : { x: '150%', position: 'absolute' }
            }
            key={currentStageName}
          >
            <CurrentStageComponent key={currentStageName} />
          </motion.div>
        </AnimatePresence>
      </div>
      {currentStageName !== 'verify' && (
        <>
          <div className={s.bullets}>
            {bullets.map((value, index) => {
              return <Dot key={index} stage={value} dotIndex={index} />
            })}
          </div>
          <div className={s.accounted}>
            Already have an account?&nbsp;
            <span className={s.link}>
              <Link to={ROUTES.login.path}>Sign In</Link>
            </span>
          </div>
        </>
      )}
    </>
  )
}
