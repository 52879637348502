import { useAppSelector } from '../../../../store'
import { notificationCenterSelectors } from '../../data/notification-center.slice'
import { NotificationCenterMessage } from '../notification-center-message/NotificationCenterMessage'
import s from './NotificationCenter.module.scss'
import { AnimatePresence } from 'framer-motion'

export const NotificationCenter = () => {
  const notifications = useAppSelector(notificationCenterSelectors.selectNotifications)

  return (
    <div className={s.NotificationCenter}>
      <AnimatePresence>
        {notifications.map((notification) => (
          <NotificationCenterMessage key={notification.id} notification={notification} />
        ))}
      </AnimatePresence>
    </div>
  )
}
