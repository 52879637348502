import s from './Signup.module.css'

export const Verify = () => {
  return (
    <>
      <div className={s.verify}>
        <div className={s.text}>
          To start using your Trillion account, you’ll have to verify your email address. Please
          check your inbox!
        </div>
        <div className={s.steps}>
          <div className={s.step}>
            <div className={s.number}>1</div>
            <div className={s.step__title}>Form</div>
          </div>
          <div className={s.delimetr} />
          <div className={s.step}>
            <div className={s.number}>2</div>
            <div className={s.step__title}>Inbox</div>
          </div>
          <div className={s.delimetr} />
          <div className={s.step}>
            <div className={s.number}>3</div>
            <div className={s.step__title}>Verify</div>
          </div>
        </div>
        <div className={s.text}>
          If you have any problem with verification your email,{' '}
          <span className={s.link}>
            <a href="mailto:admin@trillion.jewelry">please contact us</a>
          </span>
          .
        </div>
      </div>
    </>
  )
}
