import s from './Signup.module.css'
import React from 'react'
import { SignUpStage, signUpStages } from '../data/sign-up-stage'
import {
  selectCurrentStage,
  setCurrentStage,
  setFirstLoad,
  setNavigateForward,
} from '../data/signup.slice'
import { useAppDispatch, useAppSelector } from '../../../common/store'

interface Props {
  stage: SignUpStage
  dotIndex: number
}

const Dot: React.FC<Props> = ({ stage, dotIndex }) => {
  const currentStage = useAppSelector(selectCurrentStage)
  const dispatch = useAppDispatch()

  const currentStageIndex = signUpStages.get(currentStage)!.order

  const handleClick = () => {
    if (dotIndex < currentStageIndex && currentStageIndex !== signUpStages.size - 1) {
      dispatch(setCurrentStage(stage))
      dispatch(setFirstLoad(false))
      dispatch(setNavigateForward(false))
    }
  }

  return (
    <div className={s.bullet} onClick={handleClick}>
      <div className={currentStage === stage ? `${s.dot} ${s.dot__active}` : s.dot} />
    </div>
  )
}

export default Dot
