import { Outlet } from 'react-router-dom'
import laptopSVG from '../../../assets/laptop.png'
import trlnBgSVG from '../../../assets/trlnBg.svg'
import logoSVG from '../../../assets/logo.svg'
import s from './WelcomeLayout.module.scss'
import useDynamicPageTitle from '../../hooks/use-dynamic-page-title'

export const WelcomeLayout = () => {
  useDynamicPageTitle()

  return (
    <>
      <div className={s.line} />
      <div className={s.container}>
        <div className={s.wrap}>
          <div className={s.logo__mobile}>
            <img src={logoSVG} alt="Trillion Logo" />
          </div>
          <div className={s.content}>
            <Outlet />
          </div>
        </div>
        <div className={s.welcome}>
          <div className={s.welcome__bg}>
            <img src={trlnBgSVG} alt={'TRLN BG'} />
          </div>
          <div className={s.welcome__wrap}>
            <div className={s.logo}>
              <img src={logoSVG} alt={'TRLN LOGO'} />
            </div>
            <div className={s.laptop}>
              <img src={laptopSVG} alt={'TRLN LAPTOP'} />
            </div>
            <h1 className={s.title}>Welcome to your new dashboard</h1>
            <div className={s.text}>
              Open up an AR try-on feature to your customers — create a digital competitive
              advantage for your jewelry brand and and enhance your e-commerce.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
