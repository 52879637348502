import s from './Signup.module.css'
import { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import {
  selectCredentials,
  setCredentials,
  setCurrentStage,
  setNavigateForward,
} from '../data/signup.slice'
import { ICredentialData } from '../data/sign-up-stage'
import { FormButton } from '../../../common/shared-components/buttons/form-button/FormButton'
import { useAppDispatch, useAppSelector } from '../../../common/store'

export const Credentials = () => {
  const credentials = useAppSelector(selectCredentials)
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("The 'Full name' field is required"),
    email: Yup.string()
      .email('Field should contain a valid e-mail')
      .required("The 'Email' field is required"),
    password: Yup.string()
      .min(8, 'Password field should contain minimum 8 letters')
      .required("The 'Password' field is required"),
  })
  const onSubmit = (credentialData: ICredentialData) => {
    dispatch(setCurrentStage('companyInfo'))
    dispatch(setCredentials(credentialData))
    dispatch(setNavigateForward(true))
  }

  function handleCheckbox() {
    setShowPassword(() => !showPassword)
  }

  return (
    <>
      <Formik
        initialValues={credentials}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form className={`${s.form} ${s.credentials}`} noValidate>
            <div className={s.input__wrap}>
              <Field
                className={s.input}
                name="name"
                autoComplete="on"
                type="text"
                placeholder={'Full name*'}
              />
              {errors.name && touched.name && !isSubmitting ? (
                <div className={s.error}>{errors.name}</div>
              ) : null}
            </div>
            <div className={s.input__wrap}>
              <Field
                className={s.input}
                id="email"
                name="email"
                autoComplete="username"
                type="email"
                placeholder={'E-mail*'}
              />
              {errors.email && touched.email && !isSubmitting ? (
                <div className={s.error}>{errors.email}</div>
              ) : null}
            </div>
            <div className={s.input__wrap}>
              <Field
                className={s.input}
                minLength={8}
                name="password"
                id="new-password"
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                placeholder={'Create a password*'}
              />
              {errors.password && touched.password && !isSubmitting ? (
                <div className={s.error}>{errors.password}</div>
              ) : null}
            </div>
            <div className={s.checkbox__wrap} onClick={() => handleCheckbox()}>
              <div className={showPassword ? `${s.checked} ${s.checkbox}` : s.checkbox} />
              <div className={`${s.checkbox__text} ${s.checkbox_password}`}>Show password</div>
            </div>
            <div className={s.form__btn}>
              <FormButton disabled={!isValid} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
