import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const MuiFormGroups: {
  defaultProps?: ComponentsProps['MuiFormGroup']
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormGroup']
  variants?: ComponentsVariants['MuiFormGroup']
} = {
  styleOverrides: {
    root: () => ({
      '&.filterCheckboxItems': {
        gap: '10px',
        textTransform: 'lowercase',
      },
      '&.filterCheckboxItems .MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root': {
        font: 'var(--font-body)',
        gap: '14px',
        alignItems: 'center',
        marginLeft: '0',

        '& span:first-letter': {
          textTransform: 'capitalize',
        },
      },
      '&.filterCheckboxItems .MuiFormControl-root .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root':
        {
          font: 'var(--font-body)',

          '&::-moz-selection': {
            backgroundColor: 'transparent',
          },
          '&::selection': {
            backgroundColor: 'transparent',
          },
        },
    }),
  },
}

export default MuiFormGroups
