import s from './LoaderSpinner.module.scss'

interface LoaderSpinnerProps {
  size?: number
}

export const LoaderSpinner = ({ size = 4 }: LoaderSpinnerProps) => {
  return (
    <>
      <div className={s.loader_wrap}>
        <div className={s.loader} style={{ fontSize: `${size}px` }} />
      </div>
    </>
  )
}
