import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import React from 'react'

export function initMonitoring() {
  if (import.meta.env.MODE !== 'production') return

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', '127.0.0.1:5000', 'dashboard.trillion.jewelry:8001'],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeBreadcrumb: (breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null => {
      if (
        (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') &&
        typeof breadcrumb.data?.url === 'string' &&
        (breadcrumb.data.url.startsWith('data:') ||
          breadcrumb.data.url.startsWith('blob:') ||
          breadcrumb.data.url.startsWith('https://tag.trillion.jewelry'))
      ) {
        return null
      }
      return breadcrumb
    },
  })
}
