import s from './RestoreConfirmed.module.scss'

export const RestoreConfirmed = () => {
  return (
    <div className={s.restore}>
      <div className={s.title}>Reset password</div>
      <div className={s.subtitle}>
        We have sent an email with a restore password link to your email address.
      </div>
      <div className={s.subtitle}>Please click the link from the email to restore password.</div>
    </div>
  )
}
