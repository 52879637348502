import { Navigate, Link, useSearchParams } from 'react-router-dom'
import { ROUTES } from '../../../../common/router/routes'
import s from './AfterPaymentPage.module.scss'
import { Button } from '@mui/material'

export const AfterPaymentPage = () => {
  const [searchParams] = useSearchParams()

  if (searchParams.has('success')) {
    return (
      <div className={s.AfterPaymentPage}>
        <span>Thanks for purchase!</span>
        <Link to={ROUTES.billing_pricing_resolver.path}>
          <Button variant="arrow">To the billing page</Button>
        </Link>
      </div>
    )
  }

  return <Navigate to={ROUTES.billing_pricing_resolver.path} />
}
