import { Container } from '@mui/material'
import { CardWrapper } from '../../../../common/shared-components/card-wrapper/CardWrapper'
import { ProductsTitle } from '../../../../common/shared-components/products-title/ProductsTitle'

export const ShopifyImport = () => {
  return (
    <>
      <ProductsTitle title={'Shopify import'} />
      <CardWrapper withPadding={true} isRounded={true}>
        <Container>ShopifyImport</Container>
      </CardWrapper>
    </>
  )
}
