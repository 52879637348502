import { ReactNode } from 'react'
import { Navigate, RouteProps } from 'react-router-dom'
import modelingSVG from '../../assets/3d-modeling.svg'
import customersSVG from '../../assets/customers.svg'
import dashboardSVG from '../../assets/dashboard.svg'
import diamondSVG from '../../assets/diamond.svg'
import flagSVG from '../../assets/flag.svg'
import gearSVG from '../../assets/gear.svg'
import helpSVG from '../../assets/help.svg'
import logoutSVG from '../../assets/logout.svg'
import pricingSVG from '../../assets/pricing.svg'
import userSVG from '../../assets/user.svg'
import { Role } from '../../auth/dto/role.enum'
import { UserDto } from '../../auth/dto/user.dto'
import { useGetUserQuery } from '../../auth/user.api'
import { NotImplemented } from '../../pages/NotImplemented'
import { ActivationPage } from '../../pages/activation/components/ActivationPage/ActivationPage'
import { LinkExpired } from '../../pages/activation/components/LinkExpired/LinkExpired'
import { AfterPaymentPage } from '../../pages/after-payment/components/after-payment-page/AfterPaymentPage'
import { BeginningController } from '../../pages/beginning/components/beginning-controller/BeginnigController'
import { BillingGuard } from '../../pages/billing/guards/BillingGuard'
import { ChangeEmail } from '../../pages/change-email/components/ChangeEmail/ChangeEmail'
import { CreateEnterpriseInvoicePage } from '../../pages/create-enterprise-invoice/components/create-enterprise-invoice-page/CreateEnterpriseInvoicePage'
import { CreateModelingInvoicePage } from '../../pages/create-modeling-invoice/components/create-modeling-invoice-page/CreateModelingInvoicePage'
import { CreatePromoPackageInvoicePage } from '../../pages/create-promo-package-invoice/components/create-promo-package-invoice-page/CreatePromoPackageInvoicePage'
import { LogoutPage } from '../../pages/logout/LogoutPage'
import { PricingBillingResolver } from '../../pages/pricing-billing-resolver/PricingBillingResolver'
import { ProductsControllerLazy } from '../../pages/products/components/products-controller/ProductsController.lazy'
import { ShopifyImport } from '../../pages/products/components/shopify-import/ShopifyImport'
import { ProfileControllerLazy } from '../../pages/profile/components/profile-controller/ProfileController.lazy'
import { ResetPassword } from '../../pages/reset/components/reset-password/ResetPassword'
import { RestoreController } from '../../pages/restore/components/restore-controller/RestoreController'
import { SignIn } from '../../pages/signin/Signin'
import { SignUp } from '../../pages/signup/components/Signup'
import { LocalStorageUtils } from '../local-storage-utils'
import { DashboardPageLazy } from '../../pages/dashboard/components/DashboardPage/DashboardPage.lazy'
import { AddEditProductControllerLazy } from '../../pages/add-edit-product/components/add-edit-product-controller/AddEditProductController.lazy'
import { ModelingPageLazy } from '../../pages/modeling/components/modeling-page/ModelingPage.lazy'
import { BillingPageControllerLazy } from '../../pages/billing/components/billing-page-controller/BillingPageController.lazy'
import { CustomersPageLazy } from '../../pages/customers/components/CustomersPage/CustomersPage.lazy'
import { PricingPageControllerLazy } from '../../pages/pricing/components/PricingPageController/PricingPageController.lazy'
import { IntegrationControllerLazy } from '../../pages/integration/components/IntegrationController/IntegrationController.lazy'

export type AppRouteProps = ISidebarItem | TBaseRouteProps

interface TBaseRouteProps extends Pick<RouteProps, 'path'> {
  path: string
  authOnly: boolean
  roles: Role[]
  isSidebarItem?: boolean
  getElement: () => ReactNode
  guard?: JSX.Element
  title?: string
}

export interface ISidebarItem extends TBaseRouteProps {
  isSidebarItem: true
  sidebarLabel: string
  mobileOnly?: boolean
  exclude?: boolean
  icon: string
}

enum AppRoutes {
  LOGIN = 'login',
  SIGNUP = 'signup',
  RESTORE = 'restore',
  ACTIVATE = 'activate',
  LINK_EXPIRED = 'link_expired',
  AFTER_CHANGE_EMAIL = 'afterChangeEmail',
  LOGOUT = 'logout',
  RESET = 'resetLink',
  CUSTOMERS = 'customers',
  MODELING = 'modeling',
  BEGINNING = 'beginning',
  PRODUCTS = 'products',
  ADD_PRODUCT = 'addProduct',
  EDIT_PRODUCT = 'editProduct',
  SHOPIFY_IMPORT = 'shopifyImport',
  PRICING_BILLING_RESOLVER = 'billing_pricing_resolver',
  BILLING = 'billing',
  PRICING = 'pricing',
  DOCUMENTS = 'documents',
  PROFILE = 'profile',
  HELP = 'help',
  DASHBOARD = 'dashboard',
  INTEGRATION = 'integration',
  AFTER_PAYMENT = 'after_payment',
  CREATE_ENTERPRISE_INVOICE = 'create_enterprise_invoice',
  CREATE_MODELING_INVOICE = 'create_modeling_invoice',
  CREATE_PROMO_PACKAGE_INVOICE = 'create_promo_package_invoice',
}

export const ROUTES: Record<AppRoutes, AppRouteProps> = {
  [AppRoutes.LOGIN]: {
    path: '/login',
    authOnly: false,
    roles: [],
    getElement: () => <SignIn />,
    title: 'Sign in | Trillion',
  },
  [AppRoutes.SIGNUP]: {
    path: '/signup',
    authOnly: false,
    roles: [],
    getElement: () => <SignUp />,
    title: 'Sign up | Trillion',
  },
  [AppRoutes.RESTORE]: {
    path: '/restore',
    authOnly: false,
    roles: [],
    getElement: () => <RestoreController />,
    title: 'Restore password | Trillion',
  },
  [AppRoutes.ACTIVATE]: {
    path: '/activate/:userId/:activationToken',
    authOnly: false,
    roles: [],
    getElement: () => <ActivationPage />,
    title: 'Activation | Trillion',
  },
  [AppRoutes.LINK_EXPIRED]: {
    path: '/link-expired',
    authOnly: false,
    roles: [],
    getElement: () => <LinkExpired />,
    title: 'Your activation link has expired | Trillion',
  },
  [AppRoutes.AFTER_CHANGE_EMAIL]: {
    path: '/change-email/:userId/:changeEmailToken',
    authOnly: false,
    roles: [],
    getElement: () => <ChangeEmail />,
    title: 'Change email | Trillion',
  },
  [AppRoutes.RESET]: {
    path: '/resetLink/:userId/:resetToken',
    authOnly: false,
    roles: [],
    getElement: () => <ResetPassword />,
    title: 'Reset password | Trillion',
  },
  [AppRoutes.CUSTOMERS]: {
    path: '/customers',
    authOnly: true,
    roles: [Role.admin, Role.modeler],
    getElement: () => <CustomersPageLazy />,
    isSidebarItem: true,
    sidebarLabel: 'customers',
    icon: customersSVG,
    title: 'Customers | Trillion',
  },
  [AppRoutes.MODELING]: {
    path: '/modeling',
    authOnly: true,
    roles: [Role.admin, Role.modeler],
    getElement: () => <ModelingPageLazy />,
    isSidebarItem: true,
    sidebarLabel: 'modeling',
    icon: modelingSVG,
    title: 'Modeling | Trillion',
  },
  [AppRoutes.BEGINNING]: {
    path: '/beginning',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <BeginningController />,
    isSidebarItem: true,
    sidebarLabel: 'beginning',
    icon: flagSVG,
    title: 'Beginning | Trillion',
  },
  [AppRoutes.DASHBOARD]: {
    path: '/dashboard',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <DashboardPageLazy />,
    isSidebarItem: true,
    sidebarLabel: 'dashboard',
    icon: dashboardSVG,
    title: 'Dashboard | Trillion',
  },
  [AppRoutes.INTEGRATION]: {
    path: '/integration',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <IntegrationControllerLazy />,
    isSidebarItem: true,
    sidebarLabel: 'integration',
    icon: gearSVG,
    title: 'Integration | Trillion',
  },
  [AppRoutes.PRODUCTS]: {
    path: '/products',
    authOnly: true,
    roles: [Role.user, Role.admin, Role.modeler],
    getElement: () => <ProductsControllerLazy />,
    isSidebarItem: true,
    sidebarLabel: 'products',
    icon: diamondSVG,
    title: 'Products | Trillion',
  },
  [AppRoutes.ADD_PRODUCT]: {
    path: '/products/new',
    authOnly: true,
    roles: [],
    getElement: () => <AddEditProductControllerLazy />,
    title: 'Add new product | Trillion',
  },
  [AppRoutes.EDIT_PRODUCT]: {
    path: '/products/:id',
    authOnly: true,
    roles: [Role.modeler, Role.admin, Role.user],
    getElement: () => <AddEditProductControllerLazy />,
    title: 'Edit product | Trillion',
  },
  [AppRoutes.SHOPIFY_IMPORT]: {
    path: '/products/shopify-import',
    authOnly: true,
    roles: [],
    getElement: () => <ShopifyImport />,
    title: 'Shopify import | Trillion',
  },
  [AppRoutes.PRICING_BILLING_RESOLVER]: {
    path: '/pricing_billing',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <PricingBillingResolver />,
    isSidebarItem: true,
    sidebarLabel: 'billing & pricing',
    icon: pricingSVG,
    title: 'Billing & pricing | Trillion',
  },
  [AppRoutes.PRICING]: {
    path: '/pricing_billing/pricing',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <PricingPageControllerLazy />,
    title: 'Pricing | Trillion',
  },
  [AppRoutes.BILLING]: {
    path: '/pricing_billing/billing',
    authOnly: true,
    roles: [Role.user, Role.admin],
    getElement: () => <BillingPageControllerLazy />,
    guard: <BillingGuard />,
    title: 'Billing | Trillion',
  },
  [AppRoutes.AFTER_PAYMENT]: {
    path: '/payment',
    authOnly: true,
    roles: [],
    getElement: () => <AfterPaymentPage />,
    title: 'Payment | Trillion',
  },
  [AppRoutes.CREATE_ENTERPRISE_INVOICE]: {
    path: '/create_enterprise_invoice',
    authOnly: true,
    roles: [Role.admin],
    getElement: () => <CreateEnterpriseInvoicePage />,
    title: 'Create enterprise invoice | Trillion',
  },
  [AppRoutes.CREATE_MODELING_INVOICE]: {
    path: '/create_modeling_invoice',
    authOnly: true,
    roles: [Role.admin],
    getElement: () => <CreateModelingInvoicePage />,
    title: 'Create modeling invoice | Trillion',
  },
  [AppRoutes.CREATE_PROMO_PACKAGE_INVOICE]: {
    path: '/create_promo_package_invoice',
    authOnly: true,
    roles: [Role.admin],
    getElement: () => <CreatePromoPackageInvoicePage />,
    title: 'Create Promo package invoice | Trillion',
  },
  [AppRoutes.DOCUMENTS]: {
    path: '/documents',
    authOnly: true,
    roles: [],
    getElement: () => <NotImplemented />,
    title: 'Documents | Trillion',
    // sidebarLabel: 'documents',
    // icon: 'TODO',
  },
  [AppRoutes.PROFILE]: {
    path: '/profile',
    authOnly: true,
    roles: [],
    getElement: () => <ProfileControllerLazy />,
    isSidebarItem: true,
    sidebarLabel: 'profile',
    icon: userSVG,
    title: 'Profile | Trillion',
  },
  [AppRoutes.HELP]: {
    path: '/help',
    authOnly: true,
    roles: [],
    getElement: () => <NotImplemented />,
    isSidebarItem: true,
    sidebarLabel: 'help',
    icon: helpSVG,
    exclude: true,
    title: 'Help | Trillion',
  },
  [AppRoutes.LOGOUT]: {
    path: '/logout',
    authOnly: false,
    roles: [],
    getElement: () => <LogoutPage />,
    isSidebarItem: true,
    mobileOnly: true,
    sidebarLabel: 'logout',
    icon: logoutSVG,
    title: 'Logout | Trillion',
  },
}

export const getDefaultUnprotectedRoute = (): string => {
  return LocalStorageUtils.getIsOldUser() ? ROUTES.login.path : ROUTES.signup.path
}

export const getDefaultProtectedRoute = (user?: UserDto): string => {
  if (!user) {
    return '/'
  }

  if (user.role === Role.modeler || user.role === Role.admin) {
    return ROUTES.modeling.path
  } else {
    return ROUTES.beginning.path
  }
}

export const NavigateToDefaultUnprotectedRoute = () => {
  return <Navigate to={getDefaultUnprotectedRoute()} />
}

export const NavigateToDefaultProtectedRoute = () => {
  const { data: user, error } = useGetUserQuery()

  if (!user && !error) {
    return null
  }

  if (error) {
    return <Navigate to={getDefaultUnprotectedRoute()} />
  }

  return <Navigate to={getDefaultProtectedRoute(user)} />
}

export const getSidebarItems = (): ISidebarItem[] => {
  return [...Object.values(ROUTES)]
    .filter((route) => route.isSidebarItem)
    .map((route) => route as ISidebarItem)
}

export const isSidebarRouteGuard = (route: AppRouteProps): route is ISidebarItem => {
  return !!route.isSidebarItem
}
