import { Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from '../../../common/router/routes'
import { usersApi } from '../../../auth/user.api'
import { PageLoader } from '../../../common/shared-components/page-loader/PageLoader'

export const BillingGuard = () => {
  const { data: user, isLoading } = usersApi.useGetUserQuery()

  if (!user || isLoading) {
    return <PageLoader />
  }

  return user.isBillingPageActive ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.billing_pricing_resolver.path} />
  )
}
