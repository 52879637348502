import { baseApi } from '../../../common/rtk-query/base.api'
import { AvailableBillingPackagesDto } from './dto/available-billing-packages.dto'
import { SelectBillingPackageDto } from './dto/select-billing-package.dto'
import { RedirectToCheckoutDto } from './dto/redirect-to-checkout.dto'
import { BillingPackageDto } from './dto/billing-package.dto'
import { DiscountSessionDto } from './dto/discount-session.dto'
import { RefundRequestDto } from '../../billing/data/dto/refund-request.dto'
import { CancelBillingPackageDto } from './dto/cancel-billing-package.dto'

const BILLING_URL = 'billing'

export const pricingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAvailableBillingPackages: build.query<AvailableBillingPackagesDto, void>({
      query: () => ({
        url: `${BILLING_URL}/available-billing-packages`,
        method: 'GET',
      }),
    }),
    getBillingPackageById: build.query<BillingPackageDto, string>({
      query: (id) => ({
        url: `${BILLING_URL}/available-billing-packages/${id}`,
        method: 'GET',
      }),
    }),
    getPromoPackageData: build.query<BillingPackageDto, void>({
      query: () => ({
        url: `${BILLING_URL}/promo-package`,
        method: 'GET',
      }),
    }),
    selectPackage: build.mutation<RedirectToCheckoutDto, SelectBillingPackageDto>({
      query: (selectBillingPackageDto) => ({
        url: `${BILLING_URL}/select-package`,
        method: 'POST',
        data: selectBillingPackageDto,
      }),
      invalidatesTags: ['Billing'],
    }),
    selectSubscription: build.mutation<RedirectToCheckoutDto, SelectBillingPackageDto>({
      query: (selectBillingPackageDto) => ({
        url: `${BILLING_URL}/select-subscription`,
        method: 'POST',
        data: selectBillingPackageDto,
      }),
      invalidatesTags: ['Billing'],
    }),
    cancelSubscription: build.mutation<boolean, CancelBillingPackageDto>({
      query: (cancelBillingPackageDto) => ({
        url: `${BILLING_URL}/cancel-subscription`,
        method: 'POST',
        data: cancelBillingPackageDto,
      }),
      invalidatesTags: ['Billing'],
    }),
    selectEnterprisePackage: build.mutation<void, SelectBillingPackageDto>({
      query: (selectBillingPackageDto) => ({
        url: `${BILLING_URL}/request-for-manual-package`,
        method: 'POST',
        data: selectBillingPackageDto,
      }),
      invalidatesTags: ['Billing'],
    }),
    createDiscountedSession: build.mutation<RedirectToCheckoutDto, DiscountSessionDto>({
      query: (discountSessionDto) => ({
        url: `${BILLING_URL}/create-discounted-session`,
        method: 'POST',
        data: discountSessionDto,
      }),
      invalidatesTags: ['Billing'],
    }),
    createRefundToDowngradePackage: build.mutation<RedirectToCheckoutDto, RefundRequestDto>({
      query: (RefundRequestDto) => ({
        url: `${BILLING_URL}/downgrade-refund`,
        method: 'POST',
        data: RefundRequestDto,
      }),
      invalidatesTags: ['Billing'],
    }),
  }),
})
