import { baseApi } from '../../../common/rtk-query/base.api'
import { CreateModelingInvoiceDto } from './dto/create-modeling-invoice.dto'
import { InvoiceCreatedDto } from './dto/invoice-created.dto'

const BILLING_URL = 'billing'

export const createModelingInvoiceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createModelingInvoice: build.mutation<InvoiceCreatedDto, CreateModelingInvoiceDto>({
      query: (data) => ({
        url: `${BILLING_URL}/create-modeling-invoice`,
        method: 'POST',
        data,
      }),
    }),
  }),
})
