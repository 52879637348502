import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiAlerts: {
  defaultProps?: ComponentsProps['MuiAlert']
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlert']
  variants?: ComponentsVariants['MuiAlert']
} = {
  styleOverrides: {
    root: {
      '&.info': {
        alignItems: 'center',
        font: 'var(--font-body)',
        fontSize: '13px',
        lineHeight: '20px',
        borderRadius: '16px',
        padding: '2px 21px 2px 13px',

        '& .MuiAlert-action': {
          padding: '0 0 0 16px',
        },
        '& .MuiAlert-action .MuiButtonBase-root .MuiSvgIcon-root': {
          width: '16px',
          height: '16px',

          '& path': {
            fill: 'var(--color-gray)',
          },
        },
      },
    },
  },
}

export default muiAlerts
