import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiDialogActions: {
  defaultProps?: ComponentsProps['MuiDialogActions']
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogActions']
  variants?: ComponentsVariants['MuiDialogActions']
} = {
  styleOverrides: {
    root: {
      borderTop: '1px solid #E9EEF1',
      '&.MuiDialogActions-root': {
        padding: '18px 20px',
      },
      '&.confirm': {
        borderTop: 'none',
        padding: '18px 20px 38px',
        margin: '0 auto',
      },
    },
  },
}

export default muiDialogActions
