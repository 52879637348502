import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiContainers: {
  defaultProps?: ComponentsProps['MuiContainer']
  styleOverrides?: ComponentsOverrides<Theme>['MuiContainer']
  variants?: ComponentsVariants['MuiContainer']
} = {
  styleOverrides: {
    root: {
      '&.product-row-mobile': {
        borderBottom: '1px solid var(--color-gray-light)',
        padding: '0',
      },
      '&.row-mobile-wrap': {
        display: 'flex',
        gap: '20px',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '17px 20px 27px',
      },
    },
  },
}

export default muiContainers
