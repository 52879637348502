import * as Sentry from '@sentry/react'
import { useGetUserQuery } from '../../auth/user.api'
import { useEffect } from 'react'

export function MonitoringContextData() {
  const { data: user } = useGetUserQuery()

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, username: user.name })
    }
  }, [user])

  return <></>
}
