import s from './Beginning.module.scss'
import { FeatureTitle } from '../../../../common/shared-components/feature-title/FeatureTitle'
import { CardWrapper } from '../../../../common/shared-components/card-wrapper/CardWrapper'
import { UserDto } from '../../../../auth/dto/user.dto'
import { LaunchSteps } from '../launch-steps/LaunchSteps'
import { useMediaQuery } from 'react-responsive'

interface BeginningProps {
  user: UserDto
}

export function Beginning({ user }: BeginningProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    <>
      <FeatureTitle>Beginning</FeatureTitle>
      <CardWrapper withPadding={true} isRounded={isDesktop}>
        <div className={s.welcome}>Welcome, {user.name}!</div>
        <div className={s.title}>
          You&apos;re only 5 steps away from launching your first 3D/AR experience!
        </div>
        <div className={s.sub}>Follow this guide to get a head start</div>
        <LaunchSteps user={user} />
      </CardWrapper>
    </>
  )
}
