import { Link, useParams } from 'react-router-dom'
import s from './ChangeEmail.module.scss'
import { authApi } from '../../../../auth/auth.api'
import { FormError } from '../../../../common/shared-components/form-error/FormError'
import { LogoLoader } from '../../../../common/shared-components/logo-loader'
import { Button } from '@mui/material'
import { ROUTES } from '../../../../common/router/routes'

export const ChangeEmail = () => {
  const { userId = '', changeEmailToken = '' } = useParams()
  const { isLoading, error } = authApi.useConfirmUpdateEmailQuery({ changeEmailToken, userId })

  if (isLoading) {
    return <LogoLoader />
  }

  if (error) {
    return <FormError error={error} />
  }

  return (
    <>
      <div className={s.title}>Your email successfully changed</div>
      <Link to={ROUTES.profile.path}>
        <Button variant="arrow">Back to the Dashboard</Button>
      </Link>
    </>
  )
}
