import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../common/router/routes'
import { usersApi } from '../../auth/user.api'
import { PageLoader } from '../../common/shared-components/page-loader/PageLoader'

export const PricingBillingResolver = () => {
  const { data: user, isLoading, refetch } = usersApi.useGetUserQuery()

  useEffect(() => {
    if (user && user.isBillingPageActive) {
      refetch()
    }
  }, [user, refetch])

  if (isLoading || !user) {
    return <PageLoader />
  }

  if (user.isBillingPageActive) {
    return <Navigate to={ROUTES.billing.path} />
  }

  return <Navigate to={ROUTES.pricing.path} />
}
