import s from './MainLayout.module.css'
import { Sidebar } from './sidebar/Sidebar'
import { Header } from './header/Header'
import { Outlet } from 'react-router-dom'
import useDynamicPageTitle from '../../hooks/use-dynamic-page-title'
import { MonitoringContextData } from '../../monitoring/MonitoringContext'

export function MainLayout() {
  useDynamicPageTitle()

  return (
    <>
      <div className={s.layout}>
        <Sidebar />
        <div className={s.item}>
          <Header />
          <div className={s.feature}>
            <Outlet />
          </div>
        </div>
      </div>
      <MonitoringContextData />
    </>
  )
}
