import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiDialogContents: {
  defaultProps?: ComponentsProps['MuiDialogContent']
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogContent']
  variants?: ComponentsVariants['MuiDialogContent']
} = {
  styleOverrides: {
    root: {
      '&.cancel-content': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 22px 28px 22px',
      },
      '&.getintouch-desc': {
        font: 'var(--font-body)',
        color: 'var(--color-gray)',
        padding: '0 22px 28px 22px',
      },
      '&.getintouch-title': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 22px 12px 22px',
      },
      '&.getintouch-message': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 22px 30px',
      },
      '&.change-content': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 20px 28px 22px',
        overflow: 'unset',
      },
      '&.success-content': {
        display: 'flex',
        flexDirection: 'column',
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 20px 28px 22px',
        overflow: 'unset',
      },
      '&.trillion-web-cdk-popup-content': {
        // sometimes scrollbar is shown in the dialog window
        // TODO: fix and remove overflow hidden
        overflow: 'hidden',
        padding: 0,
      },
      '&.changeLog-content': {
        font: 'var(--font-body)',
        color: 'var(--color-dark)',
        padding: '0 20px 14px 22px',
        overflowX: 'unset',
      },
    },
  },
}

export default muiDialogContents
