import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiSelects: {
  defaultProps?: ComponentsProps['MuiSelect']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect']
  variants?: ComponentsVariants['MuiSelect']
} = {
  variants: [
    {
      props: { variant: 'standard' },
      style: {
        height: '36px',
        width: '100%',
        borderRadius: '3px',
        border: '1px solid #E9EEF1',
        fontFamily: 'var(--font-main)',
        fontText: '12px',
        color: 'var(--color-dark)',
        padding: '5px 0 5px 8px',
        '&.MuiInputBase-root .MuiSelect-select': {
          padding: '7px 0 5px',
        },
        '::focus': {
          padding: '0 0 24px 8px',
          lineHeight: '36px',
          backgroundColor: 'none',
        },
        ':hover': {
          border: '1px solid #000',
        },
        '::before': {
          border: 'none !important',
        },
        '::after': {
          border: 'none',
        },
      },
    },
  ],
}

export default muiSelects
