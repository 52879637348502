import { useMemo } from 'react'
import { useGetProductsForUserQuery, useGetProductsQuery } from '../data/products.api'
import { useProductsQueryParams } from './use-products-query-params'
import { ProductDto } from '../data/dto/product.dto'
import { useSearchParams } from 'react-router-dom'
import { ProductsListParamsDto } from '../data/dto/products-list-params.dto'

const ROWS_PER_PAGE = 10
const POLLING_INTERVAL = 2 * 60 * 1000 // milliseconds

interface IUseProducts {
  products: ProductDto[]
  totalPages: number
  isUserProductsFetching: boolean
  isFilteredOrSearched: boolean
  productsQueryParams: ProductsListParamsDto
}

export const useProducts = (): IUseProducts => {
  const [searchParams] = useSearchParams()
  const forUser = searchParams.get('forUser')

  const { data: productsForUser, isLoading: isLoadingForUser } = useGetProductsForUserQuery(
    { id: forUser },
    { refetchOnMountOrArgChange: true, skip: !forUser },
  )

  const queryParams = useProductsQueryParams()

  const isFilteredOrSearched =
    (queryParams.searchQuery && queryParams.searchQuery.length > 0) || !!queryParams.filters

  const { data: userProducts, isLoading: isUserProductsFetching } = useGetProductsQuery(
    queryParams,
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL,
      skip: forUser !== null,
    },
  )

  const totalPages = useMemo(() => {
    if (userProducts?.totalCount) {
      return Math.ceil(userProducts.totalCount / ROWS_PER_PAGE)
    }
    return 1
  }, [userProducts])

  return {
    products: forUser !== null ? productsForUser?.products ?? [] : userProducts?.products ?? [],
    totalPages,
    isUserProductsFetching: isUserProductsFetching || isLoadingForUser,
    isFilteredOrSearched,
    productsQueryParams: queryParams,
  }
}
