import s from './LinkExpired.module.scss'
import { useParams } from 'react-router-dom'
import { useResendActivationLinkMutation } from '../../../../auth/auth.api'
import { useAppDispatch } from '../../../../common/store'
import { notificationCenterActions } from '../../../../common/shared-components/notification-center/data/notification-center.slice'
import { parseNotificationError } from '../../../../common/shared-components/notification-center/data/helpers/parse-notification-error'
import { captureError } from '../../../../common/monitoring/captureError'

export function LinkExpired() {
  const dispatch = useAppDispatch()
  const { userId = '' } = useParams()
  const [resendActivationLink, { isSuccess, isLoading }] = useResendActivationLinkMutation()

  const handleNewActivationLink = async () => {
    try {
      await resendActivationLink({
        id: userId,
      }).unwrap()
    } catch (e) {
      console.error(e)
      dispatch(
        notificationCenterActions.show({
          type: 'error',
          title: `Error when resending activation link`,
          description: parseNotificationError(e),
        }),
      )
      captureError(e)
    }
  }

  return (
    <>
      <div className={s.title}>Login link Expired</div>
      <div className={s.text}>
        <span>Login link access expires after 24 hours and can only be used once.</span>
        <span>Please send yourself a new sign in link.</span>
      </div>
      {!isSuccess ? (
        <button className={s.btn} onClick={handleNewActivationLink} disabled={isLoading}>
          Send me a new link
        </button>
      ) : (
        <div className={s.text}>New activation link sent</div>
      )}
    </>
  )
}
