import { isFetchError } from '../../../../rtk-query/fetch-error'
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_500 } from '../types/notification-center'

export function parseNotificationError(e: unknown) {
  let description = DEFAULT_ERROR_MESSAGE
  if (isFetchError(e)) {
    description = e.data.message
    if (description === 'Internal server error') {
      description = DEFAULT_ERROR_500
    }
  }
  return description
}
