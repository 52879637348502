import { useSearchParams } from 'react-router-dom'
import { NavigateToDefaultProtectedRoute } from '../../../../common/router/routes'
import { CardWrapper } from '../../../../common/shared-components/card-wrapper/CardWrapper'
import { FeatureTitle } from '../../../../common/shared-components/feature-title/FeatureTitle'
import { CreateModelingInvoiceFormController } from '../create-modeling-invoice-form-controller/CreateModelingInvoiceFormController'

export const CreateModelingInvoicePage = () => {
  const [searchParams] = useSearchParams()

  const userId = searchParams.get('user')
  const amountStr = searchParams.get('amount')

  if (!(userId && amountStr)) {
    return <NavigateToDefaultProtectedRoute />
  }

  const amount = parseInt(amountStr)

  return (
    <div>
      <FeatureTitle>Create modeling invoice</FeatureTitle>
      <CardWrapper withPadding isRounded>
        <CreateModelingInvoiceFormController amount={amount} userId={userId} />
      </CardWrapper>
    </div>
  )
}
