import { Outlet } from 'react-router-dom'
import { Role } from '../../auth/dto/role.enum'
import { useGetUserQuery } from '../../auth/user.api'
import { NavigateToDefaultProtectedRoute } from './routes'

interface RolesCheckerProps {
  // empty array means skip role check
  roles: Array<Role>
}

export const RolesChecker = ({ roles }: RolesCheckerProps) => {
  const skipCheck = roles.length === 0

  const { data: user, isLoading } = useGetUserQuery(undefined, { skip: skipCheck })

  if (isLoading && !skipCheck) {
    return <></>
  }

  const userRole = user?.role
  const canAccess = skipCheck || (userRole && roles.includes(userRole))

  return canAccess ? <Outlet /> : <NavigateToDefaultProtectedRoute />
}
