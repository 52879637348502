import classNames from 'classnames'
import {
  TNotificationCenterMessage,
  DEFAULT_NOTIFICATION_TIMEOUT_MAP,
} from '../../data/types/notification-center'
import s from './NotificationCenterMessage.module.scss'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../../store'
import { notificationCenterActions } from '../../data/notification-center.slice'
import { motion } from 'framer-motion'

interface NotificationCenterMessageProps {
  notification: TNotificationCenterMessage
}

export const NotificationCenterMessage = ({ notification }: NotificationCenterMessageProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const delay = notification.autoCloseDelay ?? DEFAULT_NOTIFICATION_TIMEOUT_MAP[notification.type]
    if (notification.disableAutoClose || !delay) return
    const timeoutId = setTimeout(() => {
      closeNotification()
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [notification])

  const closeNotification = () => {
    dispatch(notificationCenterActions.remove(notification.id))
  }

  return (
    <motion.div
      className={classNames(s.NotificationCenterMessage, s[notification.type], {
        [s.no_description]: !notification.description,
      })}
      initial={{ x: 450 }}
      animate={{ x: 0 }}
      exit={{ x: 450 }}
      transition={{ duration: 0.2 }}
    >
      <div className={s.type_icon} />
      <div className={s.content}>
        <span className={s.title}>{notification.title}</span>
        {notification.description && (
          <span className={s.description}>{notification.description}</span>
        )}
      </div>
      <div className={s.close_icon} onClick={closeNotification} />
    </motion.div>
  )
}
