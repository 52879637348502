import { Shape } from '../../../../common/yup'
import { SelectEnterpriseBillingPackageDto } from '../../data/dto/select-enterprise-billing-package.dto'
import s from './CreateEnterpriseInvoiceForm.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { TextFieldElement } from 'react-hook-form-mui'
import { SaveButton } from '../../../../common/shared-components/buttons/save-button/SaveButton'
import { InputAdornment } from '@mui/material'
import { UserDto } from '../../../../auth/dto/user.dto'
import { BillingPackageDto } from '../../../pricing/data/dto/billing-package.dto'
import { NotificationMessage } from '../../../../common/shared-components/notification-message/NotificationMessage'
import { useState } from 'react'
import { isFetchError } from '../../../../common/rtk-query/fetch-error'
import { createEnterpriseInvoiceApi } from '../../data/create-enterprise-invoice.api'

interface CreateEnterpriseInvoiceFormProps {
  user: UserDto
  billingPackage: BillingPackageDto
}

type ICreateEnterpriseInvoice = Omit<SelectEnterpriseBillingPackageDto, 'packageId' | 'userId'>

const createEnterpriseInvoiceSchema = Yup.object().shape<Shape<ICreateEnterpriseInvoice>>({
  modelingNumber: Yup.number().integer().min(0),
  price: Yup.number().min(0),
})

export const CreateEnterpriseInvoiceForm = ({
  user,
  billingPackage,
}: CreateEnterpriseInvoiceFormProps) => {
  const [createInvoice, { isLoading }] =
    createEnterpriseInvoiceApi.useCreateEnterpriseInvoiceMutation()
  const [error, setError] = useState<string>('')

  const hookFormMethods = useForm<ICreateEnterpriseInvoice>({
    resolver: yupResolver(createEnterpriseInvoiceSchema),
    defaultValues: {
      modelingNumber: 0,
      price: 0,
    },
  })

  const {
    formState: { isValid },
    handleSubmit,
  } = hookFormMethods

  const onApply = async (data: ICreateEnterpriseInvoice) => {
    try {
      const { invoiceUrl } = await createInvoice({
        userId: user.id,
        packageId: billingPackage.id,
        ...data,
      }).unwrap()
      window.location.replace(invoiceUrl)
    } catch (e) {
      if (isFetchError(e)) {
        setError(e.data.message)
      } else {
        setError('Something terrible has happened, please contact the developers')
      }
    }
  }

  return (
    <FormProvider {...hookFormMethods}>
      <form onSubmit={handleSubmit(onApply)}>
        <div className={s.user_data}>
          <h3>User:</h3>
          <p>
            <b>Name:</b> <span>{user.name}</span>
          </p>
          <p>
            <b>Email:</b> <span>{user.email}</span>
          </p>
          <p>
            <b>Organization:</b> <span>{user.organization}</span>
          </p>
        </div>
        <div className={s.package_data}>
          <h3>Package:</h3>
          <p>
            <b>Name:</b> <span>{billingPackage.name}</span>
          </p>
          <p>
            <b>Service type:</b> <span>{billingPackage.serviceType}</span>
          </p>
        </div>

        <h3>Configuration:</h3>

        <div className={s.configuration_form}>
          <span>
            <label htmlFor="modelingNumber">3d models number:</label>
            <TextFieldElement className="standard-input" name="modelingNumber" />
          </span>

          <span>
            <label htmlFor="price">Price:</label>
            <TextFieldElement
              className="standard-input"
              name="price"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ border: 'none' }}>
                    $
                  </InputAdornment>
                ),
              }}
            />
          </span>
        </div>

        {error && (
          <div>
            <NotificationMessage type="error" text={error} />
          </div>
        )}

        <SaveButton loading={isLoading} disabled={!isValid} type="submit" title="Create invoice" />
      </form>
    </FormProvider>
  )
}
