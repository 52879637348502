import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useTableCurrentPageQueryParam() {
  const [searchParams, setParams] = useSearchParams()

  const currentPage = useMemo(() => {
    return searchParams.has('pageNumber') ? +searchParams.get('pageNumber')! : 1
  }, [searchParams])

  const setCurrentPage = useCallback(
    (page: number) => {
      searchParams.set('pageNumber', page.toString())
      setParams(searchParams)
    },
    [searchParams, setParams],
  )

  return [currentPage, setCurrentPage] as const
}
