import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiSliders: {
  defaultProps?: ComponentsProps['MuiSlider']
  styleOverrides?: ComponentsOverrides<Theme>['MuiSlider']
  variants?: ComponentsVariants['MuiSlider']
} = {
  styleOverrides: {
    root: {
      '& .MuiSlider-rail': {
        background: '#E4F2F8',
        borderRadius: '2px',
        opacity: 1,
        height: '4px',
      },
      '& .MuiSlider-mark': {
        opacity: 0,
      },
      '& .MuiSlider-track': {
        background: '#49ACCF',
        borderRadius: '2px',
        color: 'transparent',
      },
      '& .MuiSlider-thumb': {
        width: '13px',
        height: '13px',
        border: '3px solid #FFF',
        color: '#49ACCF',

        '& .MuiSlider-valueLabel': {
          backgroundColor: '#E4F2F8',
          color: '#49ACCF',
          font: 'var(--font-desc)',
          fontSize: '10px',
          lineHeight: '10px',
          fontWeight: '700',
          padding: '7px 8px 7px 6px',
          borderRadius: '6px',

          '::after': {
            content: '"SKUs"',
            fontSize: '10px',
            lineHeight: '10px',
            marginLeft: '4px',
          },
        },
        '::before': {
          boxShadow: 'none',
        },
      },
    },
  },
}

export default muiSliders
