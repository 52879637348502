import s from './FeatureTitle.module.scss'
import React, { CSSProperties } from 'react'

interface FeatureTitleProps {
  children: React.ReactNode
  align?: CSSProperties['textAlign']
  padding?: CSSProperties['padding']
}

export const FeatureTitle = ({ children, align, padding }: FeatureTitleProps) => {
  return (
    <div className={s.feature_title} style={{ textAlign: align, padding: padding }}>
      {children}
    </div>
  )
}
