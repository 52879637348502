import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiLinearProgresses: {
  defaultProps?: ComponentsProps['MuiLinearProgress']
  styleOverrides?: ComponentsOverrides<Theme>['MuiLinearProgress']
  variants?: ComponentsVariants['MuiLinearProgress']
} = {
  variants: [
    {
      props: { variant: 'determinate' },
      style: {
        background: 'var(--color-blue-opacity)',
        borderRadius: '2px',
        '&.MuiLinearProgress-root .MuiLinearProgress-bar1Determinate': {
          background: 'var(--color-blue)',
          opacity: 1,
        },
      },
    },
    {
      props: { variant: 'indeterminate' },
      style: {
        background: 'var(--color-blue-opacity)',
        borderRadius: '2px',
        '&.MuiLinearProgress-root .MuiLinearProgress-bar1Indeterminate': {
          background: 'var(--color-blue)',
          opacity: 1,
        },
        '&.MuiLinearProgress-root .MuiLinearProgress-bar2Indeterminate': {
          background: 'var(--color-blue)',
          opacity: 1,
        },
      },
    },
  ],
}

export default muiLinearProgresses
