import s from './PageLoader.module.scss'
import { LoaderSpinnerFadingCircle } from '../loader-spinner-fading-circle/LoaderSpinnerFadingCircle'

export const PageLoader = () => {
  return (
    <div className={s.PageLoader}>
      <div className={s.ellipse}>
        <LoaderSpinnerFadingCircle />
      </div>
    </div>
  )
}
