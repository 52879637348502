import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiDialogTitles: {
  defaultProps?: ComponentsProps['MuiDialogTitle']
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogTitle']
  variants?: ComponentsVariants['MuiDialogTitle']
} = {
  styleOverrides: {
    root: {
      '&.upload-title': {
        display: 'flex',
        alignItems: 'center',
        padding: '23px 27px 20px 22px',
        borderBottom: '1px solid #E9EEF1',
        justifyContent: 'space-between',
        font: 'var(--font-title)',
        color: 'var(--color-dark)',
        '&.upload-title .close-icon': {
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      '&.cancel-title': {
        display: 'flex',
        alignItems: 'center',
        padding: '23px 27px 20px 22px',
        borderBottom: '1px solid #E9EEF1',
        justifyContent: 'space-between',
        fontFamily: 'var(--font-main)',
        fontSize: 'var(--title-size)',
        lineHeight: '18px',
        marginBottom: '25px',
        color: 'var(--color-dark)',
        '&.cancel-title .close-icon': {
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      '&.getintouch-title': {
        display: 'flex',
        alignItems: 'center',
        padding: '23px 27px 20px 22px',
        borderBottom: '1px solid #E9EEF1',
        justifyContent: 'space-between',
        fontFamily: 'var(--font-main)',
        fontSize: 'var(--title-size)',
        lineHeight: '18px',
        marginBottom: '25px',
        color: 'var(--color-dark)',
        '&.cancel-title .close-icon': {
          cursor: 'pointer',
        },
      },
      '&.changeLog-title': {
        display: 'flex',
        alignItems: 'center',
        padding: '23px 27px 20px 22px',
        borderBottom: '1px solid #E9EEF1',
        justifyContent: 'space-between',
        fontFamily: 'var(--font-main)',
        fontSize: 'var(--title-size)',
        lineHeight: '18px',
        color: 'var(--color-dark)',
        '&.changeLog-title .close-icon': {
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
  },
}

export default muiDialogTitles
