import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { ROUTES } from '../router/routes'

export default function useDynamicPageTitle() {
  const location = useLocation()

  useEffect(() => {
    const currentRoute = Object.values(ROUTES).find((route) => route.path === location.pathname)

    if (currentRoute?.title) {
      document.title = currentRoute.title
    }
  }, [location.pathname])
}
