import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { MainLayout } from '../shared-components/main-layout/MainLayout'
import { WelcomeLayout } from '../shared-components/welcome-layout/WelcomeLayout'
import { ROUTES } from './routes'
import { RequireLogin } from './RequireLogin'
import { RolesChecker } from './RolesChecker'
import { AppRouteProps, NavigateToDefaultProtectedRoute } from './routes'
import { PathGuard } from './PathGuard'
import { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '../error-boundary'
import { PageLoader } from '../shared-components/page-loader/PageLoader'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const routesHandler = (...routes: AppRouteProps[]) => {
  return routes.map((route) => (
    <Route key={route.path} element={<RequireLogin require={route.authOnly} />}>
      <Route element={<RolesChecker roles={route.roles} />}>
        <Route element={<PathGuard guard={route.guard} />}>
          <Route
            path={route.path}
            element={
              <ErrorBoundary>
                <Suspense fallback={<PageLoader />}>{route.getElement()}</Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
      </Route>
    </Route>
  ))
}

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<WelcomeLayout />}>
        {routesHandler(
          ROUTES.signup,
          ROUTES.login,
          ROUTES.restore,
          ROUTES.activate,
          ROUTES.resetLink,
          ROUTES.afterChangeEmail,
          ROUTES.link_expired,
        )}
      </Route>

      <Route element={<MainLayout />}>
        {routesHandler(
          ROUTES.beginning,
          ROUTES.products,
          ROUTES.shopifyImport,
          ROUTES.editProduct,
          ROUTES.addProduct,
          ROUTES.billing_pricing_resolver,
          ROUTES.billing,
          ROUTES.pricing,
          ROUTES.profile,
          ROUTES.customers,
          ROUTES.modeling,
          ROUTES.help,
          ROUTES.dashboard,
          ROUTES.integration,
          ROUTES.after_payment,
          ROUTES.create_enterprise_invoice,
          ROUTES.create_modeling_invoice,
          ROUTES.create_promo_package_invoice,
        )}
      </Route>

      {routesHandler(ROUTES.logout)}

      <Route path="/" element={<NavigateToDefaultProtectedRoute />} />
      <Route path="*" element={<Navigate to="/" />} />
    </>,
  ),
)

export const AppRouter = () => {
  return <RouterProvider router={router} />
}
