import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useActivateQuery } from '../../../../auth/auth.api'
import { setToken } from '../../../../auth/auth.slice'
import { LocalStorageUtils } from '../../../../common/local-storage-utils'
import { FormError } from '../../../../common/shared-components/form-error/FormError'
import { useAppDispatch } from '../../../../common/store'
import s from './ActivationPage.module.scss'
import { LinkExpired } from '../LinkExpired/LinkExpired'
import { isFetchError } from '../../../../common/rtk-query/fetch-error'
import { LogoLoader } from '../../../../common/shared-components/logo-loader'

export const ActivationPage = () => {
  const [errorStatus, setErrorStatus] = useState<number>()
  const { userId = '', activationToken = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data: accessDto, isLoading, error } = useActivateQuery({ activationToken, userId })
  useEffect(() => {
    if (!isLoading && !error && accessDto) {
      navigate('/')
      dispatch(setToken(accessDto.accessToken))
      LocalStorageUtils.saveAccessToken(accessDto.accessToken)
      LocalStorageUtils.setIsOldUser()
    }
  }, [isLoading])

  useEffect(() => {
    if (isFetchError(error)) {
      setErrorStatus(error.status)
    }
  }, [error])

  return (
    <>
      {error ? (
        <div className={s.wrapper}>
          {errorStatus === 403 ? <LinkExpired /> : <FormError error={error} />}
        </div>
      ) : (
        <LogoLoader />
      )}
      {!error && (
        <>
          <div className={s.title}>Account initialization</div>
          <div className={s.subtitle}>You will be redirected to your account. Please wait.</div>
        </>
      )}
    </>
  )
}
