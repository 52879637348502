import s from './LoaderSpinnerFadingCircle.module.scss'
import classNames from 'classnames'

export const LoaderSpinnerFadingCircle = () => {
  return (
    <div className={s.LoaderSpinnerFadingCircle}>
      <div className={classNames(s.circle1, s.circle_item)}></div>
      <div className={classNames(s.circle2, s.circle_item)}></div>
      <div className={classNames(s.circle3, s.circle_item)}></div>
      <div className={classNames(s.circle4, s.circle_item)}></div>
      <div className={classNames(s.circle5, s.circle_item)}></div>
      <div className={classNames(s.circle6, s.circle_item)}></div>
      <div className={classNames(s.circle7, s.circle_item)}></div>
      <div className={classNames(s.circle8, s.circle_item)}></div>
      <div className={classNames(s.circle9, s.circle_item)}></div>
    </div>
  )
}
