import { IFetchError } from '../rtk-query/fetch-error'

export class MonitoringError extends Error {
  status: number

  constructor(fetchErrorObj: IFetchError) {
    super(fetchErrorObj?.data.message ?? 'Service unavailable, please try again later')
    this.name = fetchErrorObj?.data.error ?? 'FetchError'
    this.status = fetchErrorObj.data.statusCode
  }
}
