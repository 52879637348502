import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../common/store'
import { ServiceType } from './typings/service-type.enum'

interface IPricingState {
  emailIsSent: boolean
  selectedService: ServiceType
  isAnnually: boolean
}

const pricingState: IPricingState = {
  emailIsSent: false,
  selectedService: ServiceType.MONTHLY,
  isAnnually: false,
}

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState: pricingState,
  reducers: {
    setSelectedService: (state, { payload }: PayloadAction<ServiceType>) => {
      state.selectedService = payload
    },
    setEmailIsSent: (state, { payload }: PayloadAction<boolean>) => {
      state.emailIsSent = payload
    },
    setIsAnnually: (state, { payload }: PayloadAction<boolean>) => {
      state.isAnnually = payload
    },
  },
})

export const pricingReducer = pricingSlice.reducer

export const pricingActions = pricingSlice.actions

export const selectEmailIsSent = (root: RootState) => root.pricing.emailIsSent

export const selectIsAnnually = (root: RootState) => root.pricing.isAnnually

export const selectSelectedService = (root: RootState) => root.pricing.selectedService
