import { Box } from '@mui/material'
import s from './ProductsTitle.module.scss'
import React from 'react'

interface ProductsTitleProps {
  title: string
  children?: React.ReactNode
}

export const ProductsTitle = ({ title, children }: ProductsTitleProps) => {
  return (
    <div className={s.products_title}>
      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', flexGrow: 1 }}>
        <span className={s.products_title__label}>{title}</span>
      </Box>
      {children && <div className={s.products_title__buttons}>{children}</div>}
    </div>
  )
}
