import s from './NotificationMessage.module.scss'
import successfulSVG from '../../../assets/notification-messages/successful.svg'
import informationSVG from '../../../assets/notification-messages/information.svg'
import warningSVG from '../../../assets/notification-messages/warning.svg'
import errorSVG from '../../../assets/notification-messages/error.svg'
import classNames from 'classnames'

export type NotificationType = 'successful' | 'information' | 'warning' | 'error'

const NotificationPresets: { [key in NotificationType]: { icon: string; color: string } } = {
  successful: {
    icon: successfulSVG,
    color: '#f1f9f4',
  },
  information: {
    icon: informationSVG,
    color: '#e4f2f8',
  },
  warning: {
    icon: warningSVG,
    color: '#fef8e8',
  },
  error: {
    icon: errorSVG,
    color: '#feede9',
  },
}

interface INotificationMessage {
  type: NotificationType
  text: string
  useMinWidth?: boolean
}

export const NotificationMessage = ({ type, text, useMinWidth = true }: INotificationMessage) => {
  const color = NotificationPresets[type].color
  const icon = NotificationPresets[type].icon

  return (
    <div
      className={classNames(s.notification, { [s.minWidth]: useMinWidth })}
      style={{ backgroundColor: color }}
    >
      <div className={s.message}>
        <img src={icon} className={s.icon} alt={type} />
        <span className={s.text}>{text}</span>
      </div>
      {/* TODO: close button logic */}
      {/* <img src={closeSVG} className={s.close} alt="close"></img> */}
    </div>
  )
}
