import React from 'react'
import { CompanyInfo } from '../components/CompanyInfo'
import { Credentials } from '../components/Credentials'
import { Verify } from '../components/Verify'

export type SignUpStage = 'companyInfo' | 'credentials' | 'verify'

interface IStage {
  element: React.FC
  order: number
}

export const signUpStages = new Map<SignUpStage, IStage>([
  ['credentials', { order: 0, element: Credentials }],
  ['companyInfo', { order: 1, element: CompanyInfo }],
  ['verify', { order: 2, element: Verify }],
])

export const bullets = Array.from(signUpStages.entries())
  .sort((a, b) => a[1].order - b[1].order)
  .map((value) => value[0])

export interface ICredentialData {
  name: string
  email: string
  password: string
}
export interface ICompanyInfoData {
  job: string
  company: string
  website: string
}
