import s from './RestoreController.module.scss'
import { RestoreConfirmed } from '../restore-confirmed/RestoreConfirmed'
import { AnimatePresence, motion } from 'framer-motion'
import { Restore } from '../restore/Restore'
import { useState } from 'react'

export const RestoreController = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  return (
    <>
      <AnimatePresence>
        <div className={s.container}>
          {!isFormSubmitted && (
            <motion.div
              className={s.reset_slide}
              initial={{ left: 'unset' }}
              animate={{ left: '0' }}
              exit={{ left: '-100%' }}
            >
              <Restore setIsFormSubmitted={setIsFormSubmitted} />
            </motion.div>
          )}
          {isFormSubmitted && (
            <motion.div
              className={s.confirmed_slide}
              initial={{ left: '100%' }}
              animate={{ left: '0' }}
            >
              <RestoreConfirmed />
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </>
  )
}
