import { baseApi } from '../common/rtk-query/base.api'
import { AccessTokenDto } from './dto/access-token.dto'
import { ActivateDto } from './dto/activate.dto'
import { LoginDto } from './dto/login.dto'
import { SignUpDto } from './dto/signup.dto'
import { ForgotPasswordDto } from './dto/forgot-password.dto'
import { ResetPasswordDto } from './dto/reset-password.dto'
import { UpdateUserEmailDto } from './dto/update-user-email.dto'
import { ConfirmUpdateEmailDto } from './dto/confirm-update-email.dto'
import { UpdateUserPasswordDto } from './dto/update-user-password.dto'
import { ResendActivationLinkDto } from './dto/resend-activation-link.dto'

const AUTH_URL = 'auth'

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signup: build.mutation<void, SignUpDto>({
      query: (signupData) => ({
        url: `${AUTH_URL}/signup`,
        method: 'POST',
        data: { ...signupData },
      }),
    }),
    login: build.mutation<AccessTokenDto, LoginDto>({
      query: (credentials) => ({
        url: `${AUTH_URL}/login`,
        method: 'POST',
        data: { ...credentials },
        withCredentials: true,
      }),
      invalidatesTags: ['User', 'Product', 'Products'],
    }),
    logout: build.query<void, void>({
      query: () => ({ url: `${AUTH_URL}/logout`, method: 'GET', withCredentials: true }),
      keepUnusedDataFor: 0,
    }),
    activate: build.query<AccessTokenDto, ActivateDto>({
      query: (activationDto) => ({
        url: `${AUTH_URL}/activate`,
        method: 'POST',
        data: { ...activationDto },
      }),
      keepUnusedDataFor: 0,
    }),
    resendActivationLink: build.mutation<void, ResendActivationLinkDto>({
      query: (userId) => ({
        url: `${AUTH_URL}/resend-activation-link`,
        method: 'POST',
        data: { ...userId },
      }),
    }),
    forgotPassword: build.mutation<void, ForgotPasswordDto>({
      query: (email) => ({ url: `${AUTH_URL}/forgotPassword`, method: 'POST', data: { ...email } }),
    }),
    resetPassword: build.mutation<AccessTokenDto, ResetPasswordDto>({
      query: (data) => ({
        url: `${AUTH_URL}/resetPassword`,
        method: 'POST',
        data: { ...data },
      }),
    }),
    requestUpdateEmail: build.mutation<void, UpdateUserEmailDto>({
      query: (data) => ({ url: `${AUTH_URL}/request-update-email`, method: 'POST', data }),
    }),
    confirmUpdateEmail: build.query<void, ConfirmUpdateEmailDto>({
      query: (data) => ({ url: `${AUTH_URL}/confirm-update-email`, method: 'POST', data }),
      keepUnusedDataFor: 0,
    }),
    updatePassword: build.mutation<void, UpdateUserPasswordDto>({
      query: (data) => ({ url: `${AUTH_URL}/update-password`, method: 'POST', data }),
    }),
  }),
})

export const {
  useSignupMutation,
  useActivateQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useResendActivationLinkMutation,
} = authApi
