import { Link, useLocation } from 'react-router-dom'
import React from 'react'
import s from './Sidebar.module.css'
import { ISidebarItem } from '../../../router/routes'
import classNames from 'classnames'

interface Props {
  route: ISidebarItem
  setIsOpen: (value: boolean) => void
}

const MenuItem: React.FC<Props> = ({ route, setIsOpen }) => {
  const location = useLocation()

  return (
    <>
      <Link to={route.path} onClick={() => setIsOpen(false)}>
        <div
          className={classNames(s.item__wrap, {
            [s.item__wrap_active]: location.pathname.includes(route.path),
          })}
        >
          <div className={s.item}>
            <div className={s.icon}>
              <img src={route.icon} alt="" />
            </div>
            <div className={s.link}>{route.sidebarLabel}</div>
          </div>
        </div>
      </Link>
    </>
  )
}

export default MenuItem
