import { configureStore } from '@reduxjs/toolkit'
import { signupFormReducer } from '../pages/signup/data/signup.slice'
import { authReducer } from '../auth/auth.slice'
import { baseApi } from './rtk-query/base.api'
import { editProductReducer } from '../pages/add-edit-product/data/edit-product.slice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { userProfileReducer } from '../pages/profile/data/user-profile.slice'
import { pricingReducer } from '../pages/pricing/data/pricing.slice'
import { notificationCenterReducer } from './shared-components/notification-center/data/notification-center.slice'

export const store = configureStore({
  reducer: {
    formData: signupFormReducer,
    auth: authReducer,
    editProduct: editProductReducer,
    user: userProfileReducer,
    pricing: pricingReducer,
    notificationCenter: notificationCenterReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
