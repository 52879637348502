export type NotificationCenterMessageType = 'successful' | 'information' | 'warning' | 'error'

export type TCreateNotificationCenterMessage = {
  type: NotificationCenterMessageType
  title: string
  description?: string
  autoCloseDelay?: number
  disableAutoClose?: boolean
}

export type TNotificationCenterMessage = {
  id: string
} & TCreateNotificationCenterMessage

export const DEFAULT_NOTIFICATION_TIMEOUT_MAP: Record<
  NotificationCenterMessageType,
  number | null
> = {
  successful: 3000,
  information: 3000,
  error: null,
  warning: null,
}

export const DEFAULT_ERROR_TITLE = 'Service unavailable, please try again later'

export const DEFAULT_ERROR_TITLE_COMPACT = 'Service unavailable'

export const DEFAULT_ERROR_MESSAGE = 'Please try to reload page or contact us!'

export const DEFAULT_ERROR_500 = 'Service unavailable, please try again later'
