import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../common/store'
import { ICompanyInfoData, ICredentialData, SignUpStage } from './sign-up-stage'

interface ISignUpFormState {
  currentStage: SignUpStage
  credentials: ICredentialData
  companyInfo: ICompanyInfoData
  isFirstLoad: boolean
  isNavigateForward: boolean
}

const initialState: ISignUpFormState = {
  currentStage: 'credentials',
  credentials: {
    name: '',
    email: '',
    password: '',
  },
  companyInfo: {
    job: '',
    company: '',
    website: '',
  },
  isFirstLoad: true,
  isNavigateForward: false,
}

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setCurrentStage: (state, { payload }: PayloadAction<SignUpStage>) => {
      state.currentStage = payload
    },
    setCredentials: (state, { payload }: PayloadAction<ICredentialData>) => {
      state.credentials = payload
    },
    setCompanyInfo: (state, { payload }: PayloadAction<ICompanyInfoData>) => {
      state.companyInfo = payload
    },
    setFirstLoad: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstLoad = payload
    },
    setNavigateForward: (state, { payload }: PayloadAction<boolean>) => {
      state.isNavigateForward = payload
    },
  },
})

export const { setCurrentStage, setCredentials, setFirstLoad, setNavigateForward } =
  credentialsSlice.actions

export const signupFormReducer = credentialsSlice.reducer

export const selectCredentials = (root: RootState) => root.formData.credentials

export const selectCurrentStage = (root: RootState) => root.formData.currentStage

export const selectFirstLoad = (root: RootState) => root.formData.isFirstLoad

export const selectNavigateForward = (root: RootState) => root.formData.isNavigateForward
