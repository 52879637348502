import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiMenus: {
  defaultProps?: ComponentsProps['MuiMenu']
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu']
  variants?: ComponentsVariants['MuiMenu']
} = {
  styleOverrides: {
    root: {
      '&.product-row-menu .MuiMenu-paper .MuiList-root': {
        padding: '3px 0',
        minWidth: '103px',
      },
      '&.product-row-menu .MuiMenu-paper': {
        transform: 'translate(0, 6px)',
        boxShadow: '0px 2px 20px rgba(34, 45, 51, 0.08)',
      },
    },
  },
}

export default muiMenus
