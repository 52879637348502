import { useSearchParams } from 'react-router-dom'
import { ProductsListParamsDto } from '../data/dto/products-list-params.dto'
import { ProductFilters } from '../data/dto/product-filters.dto'
import { OrderDirection } from '../data/typings/order-direction.enum'
import { useTableCurrentPageQueryParam } from '../../../common/hooks/use-table-current-page-query-param'
import { useMemo } from 'react'

const ROWS_PER_PAGE = 10

export const useProductsQueryParams = () => {
  const [searchParams] = useSearchParams()
  const [currentPage] = useTableCurrentPageQueryParam()

  const queryParams: ProductsListParamsDto = useMemo(() => {
    const filtersQuery = searchParams.get('filters')
    const filters = filtersQuery ? (JSON.parse(filtersQuery) as ProductFilters) : undefined

    return {
      pageNumber: currentPage,
      pageSize: ROWS_PER_PAGE,
      searchQuery: searchParams.get('searchQuery') ?? undefined,
      filters,
      orderBy: searchParams.get('orderBy') ?? undefined,
      orderDir: (searchParams.get('orderDir') as OrderDirection) ?? undefined,
    }
  }, [searchParams, currentPage])

  return queryParams
}
