import { ComponentsProps } from '@mui/material/styles/props'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { Theme } from '@mui/material'
import { ComponentsVariants } from '@mui/material/styles/variants'

const muiTabs: {
  defaultProps?: ComponentsProps['MuiTabs']
  styleOverrides?: ComponentsOverrides<Theme>['MuiTabs']
  variants?: ComponentsVariants['MuiTabs']
} = {
  styleOverrides: {
    root: {
      '&.packages-tabs': {
        display: 'inline-flex',
        borderRadius: '8px',
        minHeight: '35px',
        width: 'auto',
        margin: '0 0 25px 0',
      },
      '&.packages-tabs .MuiTabs-scroller': {
        flex: 'unset',
        width: 'auto',
        borderRadius: '8px',
      },
      '&.packages-tabs .MuiTabs-flexContainer': {
        gap: '3px',
        background: 'var(--color-blue-light)',
        alignItems: 'center',
        width: 'auto',
        height: '35px',
        padding: '0',
      },
      '&.packages-tabs .MuiTabs-flexContainer .MuiButtonBase-root': {
        font: 'var(--font-body)',
        color: '#222D33;',
        textTransform: 'capitalize',
        padding: '2px 9px 0 10px',
        height: '35px',
      },
      '&.packages-tabs .MuiTabs-flexContainer .Mui-selected': {
        font: 'var(--font-body)',
        color: 'var(--color-blue)',
        borderRadius: '6px',
        textTransform: 'capitalize',
        padding: '2px 9px 0 10px',
        minHeight: 'unset',
        height: '27px',
      },
      '&.packages-tabs .MuiTabs-fixed .MuiTabs-indicator': {
        backgroundColor: 'unset',
        height: '27px',
        bottom: '4px',

        '&:after': {
          position: 'absolute',
          content: '""',
          margin: '0 auto',
          display: 'block',
          opacity: 1,
          backgroundColor: 'var(--color-white)',
          height: '27px',
          borderRadius: '6px',
          width: 'calc(100% - 8px)',
          left: '50%',
          transform: 'translateX(-50%)',
        },
      },
    },
  },
}

export default muiTabs
