import { CreatePromoPackageInvoiceForm } from '../create-promo-package-invoice-form/CreatePromoPackageInvoiceForm'
import { isFetchError } from '../../../../common/rtk-query/fetch-error'
import { NotificationMessage } from '../../../../common/shared-components/notification-message/NotificationMessage'
import { usersApi } from '../../../../auth/user.api'
import { pricingApi } from '../../../pricing/data/pricing.api'
import { PageLoader } from '../../../../common/shared-components/page-loader/PageLoader'

interface CreatePromoPackageInvoiceFormControllerProps {
  userId: string
}

export const CreatePromoPackageInvoiceFormController = ({
  userId,
}: CreatePromoPackageInvoiceFormControllerProps) => {
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = usersApi.useGetUserByIdQuery(userId)
  const {
    data: billingPackage,
    isLoading: isPackageLoading,
    error: packageError,
  } = pricingApi.useGetPromoPackageDataQuery()

  if (isPackageLoading || isUserLoading) {
    return <PageLoader />
  }

  if (packageError || !billingPackage) {
    return (
      <>
        {isFetchError(packageError) && (
          <NotificationMessage type={'error'} text={packageError.data.message} />
        )}
      </>
    )
  }

  if (userError || !user) {
    return (
      <>
        {isFetchError(userError) && (
          <NotificationMessage type={'error'} text={userError.data.message} />
        )}
      </>
    )
  }

  return <CreatePromoPackageInvoiceForm user={user} billingPackage={billingPackage} />
}
