import { SerializedError } from '@reduxjs/toolkit'

export type FetchErrorType = unknown

export interface IFetchError {
  data: {
    error: string
    message: string
    statusCode: number
  }
  status: number
}

export const isFetchError = (error: FetchErrorType): error is IFetchError => {
  return (
    error !== undefined &&
    error !== null &&
    typeof error === 'object' &&
    'status' in error &&
    'data' in error
  )
}

export const isApplicationError = (error: FetchErrorType): error is SerializedError => {
  return error !== undefined && !isFetchError(error)
}
